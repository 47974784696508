import ClientStep from "./step_0.5_Clients";
import CollaboratorStep from "./step_0_Collaborator";
import ExperienceStep from "./step_1_Experience";
import EducationStep from "./step_2_Education";
import ExpertiseStep from "./step_3_Expertise";
import SkillStep from "./step_4_Skill";
import ProfileStep from "./step_5_Profile";
import AwardStep from "./step_6_Award";
import PortfolioStep from "./step_7_Portfolio";
import LanguageStep from "./step_8_Language";


const CreationStep = (
    {
        language,
        setLanguage,
        step,
        portfolio,
        setPortfolio,
        profiles,
        setProfiles,
        awards,
        setAwards,
        clients,
        setClients,
        educations,
        setEducations,
        experiences,
        setExperiences,
        expertises,
        setExpertises,
        setSkills,
        skills,
        stepID,
        collaborator,
        setCollaborator,
        collaborators,
        setCollaborators,
        handleClose,
        collaboratorID,
        intro,
        setIntro
    }) => {
        console.log("step", step)
    const expertise = step?.includes("Expertise")
    const skill = step?.includes("Skill")
    const languages = step?.includes("Language")
    const experience = step?.includes("Experience")
    const education = step?.includes("Education")
    const profile = step?.includes("Profile")
    const award = step?.includes("Award")
    const portfolios = step?.includes("Portfolio")
    const client = step?.includes("Client")
    const contact = step?.includes("Collaborator") || step?.includes("Intro")
    return (<>
        {expertise && <ExpertiseStep handleClose={handleClose} collaboratorID={collaboratorID} collaborator={collaborator}
            setCollaborator={setCollaborator} collaborators={collaborators} setCollaborators={setCollaborators}
            expertiseList={expertises} setExpertiseList={setExpertises} step={step} stepID={stepID}
        />}
        {skill && <SkillStep handleClose={handleClose} collaboratorID={collaboratorID} collaborator={collaborator}
            setCollaborator={setCollaborator} collaborators={collaborators} setCollaborators={setCollaborators}
            ObjectList={skills} setObjectList={setSkills} step={step} stepID={stepID}
        />}
        {experience && <ExperienceStep handleClose={handleClose} collaboratorID={collaboratorID} collaborator={collaborator}
            setCollaborator={setCollaborator} collaborators={collaborators} setCollaborators={setCollaborators}
            ObjectList={experiences} setObjectList={setExperiences} step={step} stepID={stepID}
        />}
        {education && <EducationStep handleClose={handleClose} collaboratorID={collaboratorID} collaborator={collaborator}
            setCollaborator={setCollaborator} collaborators={collaborators} setCollaborators={setCollaborators}
            ObjectList={educations} setObjectList={setEducations} step={step} stepID={stepID}
        />}
        {profile && <ProfileStep handleClose={handleClose} collaboratorID={collaboratorID} collaborator={collaborator}
            setCollaborator={setCollaborator} collaborators={collaborators} setCollaborators={setCollaborators}
            ObjectList={profiles} setObjectList={setProfiles} step={step} stepID={stepID}
        />}
        {award && <AwardStep handleClose={handleClose} collaboratorID={collaboratorID} collaborator={collaborator}
            setCollaborator={setCollaborator} collaborators={collaborators} setCollaborators={setCollaborators}
            ObjectList={awards} setObjectList={setAwards} step={step} stepID={stepID}
        />}
        {portfolios && <PortfolioStep handleClose={handleClose} collaboratorID={collaboratorID} collaborator={collaborator}
            setCollaborator={setCollaborator} collaborators={collaborators} setCollaborators={setCollaborators}
            ObjectList={portfolio} setObjectList={setPortfolio} step={step} stepID={stepID}
        />}
        {contact && <CollaboratorStep handleClose={handleClose} collaboratorID={collaboratorID} collaborator={collaborator}
            setCollaborator={setCollaborator} collaborators={collaborators} setCollaborators={setCollaborators}
            ObjectList={collaborators} setObjectList={setCollaborators} step={step} stepID={stepID} introFile={intro} setIntroFile={setIntro}
        />}
        {client && <ClientStep handleClose={handleClose} collaboratorID={collaboratorID} collaborator={collaborator}
            setCollaborator={setCollaborator} collaborators={collaborators} setCollaborators={setCollaborators}
            ObjectList={clients} setObjectList={setClients} step={step} stepID={stepID}
        />}
         {languages && <LanguageStep handleClose={handleClose} collaboratorID={collaboratorID} collaborator={collaborator}
            setCollaborator={setCollaborator} collaborators={collaborators} setCollaborators={setCollaborators}
            ObjectList={language} setObjectList={setLanguage} step={step} stepID={stepID}
        />}

    </>

    );

};

export default CreationStep;
