import { useLazyQuery } from "@apollo/client";
import { Button, Card, CardContent, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { EVAL_PERIODS, EVAL_PERIODS_BY_ID, GET_COLLABORATORS } from "../Ratings/query";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../contexts/auth-context";
import { GET_COMPANY_BY_ID } from "../CompanyPage/Company_View_Page/query.gql";
import { GET_COLLABORATORS_BY_COMPANY_ID } from "../Collaborator/Collaborator_View_Page/query.gql";

const AnalyticsItem = ({ bgcolor, count, btnName, icon, url }) => {
  let navigate = useNavigate();
  const jwt = localStorage.getItem("jwtToken");
  const role = localStorage.getItem('userRole')
  const [getCollaborators] = useLazyQuery(GET_COLLABORATORS)
  const [getEvals_] = useLazyQuery(GET_COLLABORATORS_BY_COMPANY_ID)
  
  const [eval_period, setEval_period] = useState([])

  const [getCompany, { data, loading, error }] = useLazyQuery(GET_COMPANY_BY_ID);
  const { user } = useContext(AuthContext);



    useEffect(() => {
   
      if(role == 'administrator'){
        get_All_COllaborators()
       }
      if (user?.id) {
        get_Company();
      }
    }, [user?.id]);
    
    const get_Company = async () => {
      try {
       await getCompany({
          variables: {
            userId: user?.id,
          },
          context: {
            headers: {
              authorization: `Bearer ${jwt}`,
            },
          },
        });

      } catch (error) {
        console.error("Erro ao buscar dados da empresa:", error);
      }
    };
    
    

  useEffect(() => {
    if (data) {

      data.companies?.data?.map((tx) => {
        if (user?.id === Number(tx?.attributes.users_permissions_user.data.id)) {
             const companyId = tx?.attributes.users_permissions_user.data.id;
            get_Eval(tx.id);

        }
      }
      )

    }


    //get_Eval_()


  }, [data, loading, error])

  const get_Eval = async (companyId) => {

    if (!companyId) {
      console.error("ID da empresa está ausente.");
      return;
    }
  
    try {

    await getEvals_({
      context: {
        headers: {
          authorization: `Bearer ${jwt}`,
        },
      },
      variables: {
        companyId: companyId,
        filters: {
          isActive: {
            eq: true,
          },
          companies: {
            id: {
              eq: companyId,
            },
          },
        },
      },

    }).then((data) => {

      //  const duplicatCollabs =item?.attributes?.collaborators?.data.filter((item, index) => eval_item?.indexOf(item.map((xx)=> xx.id )) === index)
      const evalData = data?.data?.collaborators.data
      //console.log("data?.data?.collaborators", data?.data?.collaborators.data);
      
      setEval_period(evalData)

    });
  } catch (error) {
    console.error("Erro ao buscar períodos de avaliação:", error);
  }

  };

  const get_All_COllaborators = async (companyId) => {
    getCollaborators({
      context: {
        headers: {
          authorization: `Bearer ${jwt}`,
        },
      },
      //fetchPolicy: "no-cache",
    }).then((data) => {

      const evalData = data?.data?.collaborators.data
      console.log("evalData", evalData);
   /*    const transformedData = evalData.flatMap((collaborator) => {
        // Obter nome do colaborador
        const collaboratorName = collaborator?.attributes?.name || "N/A";

        // Iterar pelo array de avaliações dentro de cada colaborador
        return collaborator?.attributes?.evaluations?.data.map((evaluation) => {

          const companyData = evaluation?.attributes?.companies?.data?.[0]; // Obtém o primeiro objeto do array de empresas, se existir
          const companyName = companyData?.attributes?.name || "N/A"; 
          return {
        
            name: collaboratorName,
            company: companyName, // Assume o ID da primeira empresa, se houver
            evaluationId: evaluation?.id || "N/A",
            star: evaluation?.attributes?.star || "N/A",
            comment: evaluation?.attributes?.Comment || "N/A",
          };
        }) || [];
      }); */
      
      //console.log("Transformed Data", transformedData);
      
      
      setEval_period(evalData)

    


    }).catch((error) => {
      console.log("erro", error);

    })
  }


  return (
    <Card
      elevation={0}
      style={{
        background: bgcolor,
        borderRadius: "15px",
        textAlign: "center",
        height: "120px",
      }}
    >
      <CardContent style={{ textAlign: "center" }}>
        {icon && <div style={{ display: "block" }}>{icon}</div>}

        <Typography variant="h6" style={{ fontWeight: "bold", opacity: 0.6 }}>
        {eval_period.reduce((total, period) => {
    const evaluationsCount = period?.attributes?.evaluations?.data?.length || 0;
    return total + evaluationsCount;
  }, 0)}
   </Typography>

        <Button
          variant="text"
          size="small"
          fullWidth
          onClick={() => navigate(url)}
        >
          {btnName}
        </Button>
      </CardContent>
    </Card>
  );
};

export default AnalyticsItem;
