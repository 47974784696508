const Section = ({ icon, title, element }) => {
  return (
    <>
      <div className="mt-10">
        <h4 className="curv-title mb-2 flex items-center gap-2">
          <span className="self-center augmented-top">{icon}</span>
          <span className="self-center">{title}</span>
        </h4>
        {element}
      </div>
    </>
  );
};

export default Section;