import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { GET_COLLABORATOR_EVALUATIONS_BY_COLLABORATTOR_ID } from "../../Collaborator/Collaborator_View_Page/query.gql";
import { Avatar, Box, Card, CardContent, CardHeader, Divider, Grid, List, ListItem, ListItemText, Rating, Typography } from "@mui/material";

export default function ViewEvaluation(){
      const { collabId } = useParams();
      const jwt = localStorage.getItem("jwtToken");
 const [evalCollab] = useLazyQuery(GET_COLLABORATOR_EVALUATIONS_BY_COLLABORATTOR_ID);


 const [evaluationsCollaborator, setEvaluationsCollaborator] = useState([]);

  useEffect(() => {
    evalCollab({
      context: {
        headers: {
          authorization: `Bearer ${jwt}`,
        },
      },
      variables: {
        collaboratorId: collabId

      },
      
    }).then(data => {
        setEvaluationsCollaborator(data.data.evaluations.data)
    })
  }, [collabId]);

console.log("evaluationsCollaborator", evaluationsCollaborator);
const collaborator = evaluationsCollaborator[0]?.attributes.collaborator?.data.attributes || {};
const evaluationGeneralStar = evaluationsCollaborator.reduce((acc, evaluation) => acc + evaluation.attributes.star, 0) / evaluationsCollaborator.length || 0;

const getStars = (percentage) => (percentage / 100) * 5;

    return(
<>

        <div className="p-6">
        <div className="controls-bar">
          <div className="flex items-center ml-auto">
            <a
              href="/evaluation"
              className='border border-[#F89220] text-[#F89220] py-3.5 px-6 hover:opacity-60 rounded-[0.25rem] mr-2'>
              Voltar
            </a>
          </div>
        </div>


        <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        gap="30px"
        p={4}
        sx={{
          maxWidth: 800,
          margin: "auto",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          borderRadius: "8px",
          backgroundColor: "#fff",
        }}
      >
        {/* Perfil do Colaborador */}
        <Box
          display="flex"
          alignItems="center"
          gap="16px"
          sx={{
            width: "100%",
            padding: "16px",
            border: "1px solid #ddd",
            borderRadius: "8px",
            backgroundColor: "#f9f9f9",
          }}
        >
          <Avatar
            alt={collaborator.name || "Avatar"}
            src={collaborator.photo || ""}
            sx={{ width: 64, height: 64 }}
          >
            {!collaborator.photo && collaborator.name?.[0]}
          </Avatar>
          <Box>
            <Typography variant="h6" fontWeight="bold">
              {collaborator.name || "Colaborador"}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Avaliação Geral
            </Typography>
            <Box display="flex" alignItems="center" mt={1}>
              <Rating
                size="large"
                readOnly
                value={getStars(evaluationGeneralStar)}
                precision={0.5}
              />
              <Typography variant="body2" ml={2}>
                ({evaluationGeneralStar.toFixed(1)}%)
              </Typography>
            </Box>
          </Box>
        </Box>
  
        {/* Lista de Avaliações */}
        {evaluationsCollaborator.map((evaluation) => (
          <Box
            key={evaluation.id}
            sx={{
              width: "100%",
              border: "1px solid #ddd",
              borderRadius: "8px",
              padding: "16px",
              backgroundColor: "#f9f9f9",
              mb: 3,
            }}
          >
            {/* Nome da Empresa */}
            <Typography variant="h6" fontWeight="bold" gutterBottom>
              Empresa: {evaluation.attributes.companies.data[0]?.attributes.name}
            </Typography>
  
            {/* Método e Critérios Avaliados */}
            {evaluation.attributes.evaluation_methods.data.map((method, methodIndex) => (
              <Box key={methodIndex} mb={3}>
                {/* Nome do Método */}
                <Typography variant="h6" fontWeight="bold" gutterBottom>
                  Método: {method.attributes.name}
                </Typography>
  
                {/* Critérios Avaliados */}
                {evaluation.attributes.scores.data.map((score, criterionIndex) => (
                  <Box
                    key={criterionIndex}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      mb: 2,
                      padding: "8px",
                      border: "1px solid #ccc",
                      borderRadius: "8px",
                      backgroundColor: "#fff",
                    }}
                  >
                    {/* Nome e Peso */}
                    <Box>
                      <Typography variant="body1" fontWeight="bold">
                        {score.attributes.criterion.data.attributes.name}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        Peso: {score.attributes.Score}%
                      </Typography>
                    </Box>
  
                    {/* Avaliação em Estrelas */}
                    <Rating
                      size="large"
                      readOnly
                      value={getStars(score.attributes.Score)}
                      precision={0.5}
                    />
                  </Box>
                ))}
              </Box>
            ))}
  
            {/* Avaliação Geral */}
            <Box display="flex" alignItems="center" justifyContent="space-between" mt={2}>
              <Typography variant="body1" fontWeight="bold">
                Avaliação Geral:
              </Typography>
              <Box display="flex" alignItems="center">
                <Rating
                  size="large"
                  readOnly
                  value={getStars(evaluation.attributes.star)}
                  precision={0.5}
                />
                <Typography variant="body2" ml={2}>
                  ({evaluation.attributes.star}%)
                </Typography>
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
      </div>
      </>
    );
    
}