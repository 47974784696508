
const LineSteps = ({ btnSteps, action }) => {
  return (
    <>
      <div className="lineSteps flex flex-col space-y-4 mt-4">
        {
          btnSteps.map((btn, index) => (
            <button
              key={index}
              type="button"
              className="btnStep w-[80%] mx-auto flex items-center gap-2.5"
            >
              <span className={`rounded-full w-[20px] aspect-square h-[20px] bg-[#F89220] block`}></span>
              <span className="font-bold capitalize">
                {btn.text}
              </span>
            </button>
          ))
        }
      </div>
    </>
  );
};

export default LineSteps;