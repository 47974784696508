import React, { useContext } from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import i18n from '../../../translate/i18n';
import { LanguageContext } from '../../../translate/languages/LanguageContext';

// Registrar os componentes necessários do Chart.js
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const LineChartExample = () => {
  const { selectedLanguage } = useContext(LanguageContext);

  const data = {
    labels: [
      i18n.t('month.jan', { lng: selectedLanguage }),
      i18n.t('month.feb', { lng: selectedLanguage }),
      i18n.t('month.mar', { lng: selectedLanguage }),
      i18n.t('month.apr', { lng: selectedLanguage }),
      i18n.t('month.may', { lng: selectedLanguage }),
      i18n.t('month.jun', { lng: selectedLanguage }),
      i18n.t('month.jul', { lng: selectedLanguage }),
      i18n.t('month.aug', { lng: selectedLanguage }),
      i18n.t('month.sep', { lng: selectedLanguage }),
      i18n.t('month.oct', { lng: selectedLanguage }),
      i18n.t('month.nov', { lng: selectedLanguage }),
      i18n.t('month.dec', { lng: selectedLanguage })
    ],
    datasets: [
      {
        label: 'Monthly Data',
        data: [1000, 1500, 2000, 2500, 1800, 2000, 2200, 2000, 2800, 3500, 4000, 3000],
        borderColor: '#FFA500', // Cor da linha (laranja)
        borderWidth: 2, // Espessura da linha
        pointBackgroundColor: '#FFA500', // Cor dos pontos
        pointBorderColor: '#FFA500', // Cor da borda dos pontos
        pointRadius: 5, // Tamanho dos pontos
        pointHoverRadius: 7, // Tamanho dos pontos ao passar o mouse
        tension: 0.2, // Linha um pouco mais rígida
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false, // Remover legenda para coincidir com o exemplo
      },
      tooltip: {
        enabled: true, // Habilitar tooltips
      },
    },
    scales: {
      x: {
        grid: {
          color: '#e0e0e0', // Linhas da grade horizontal mais claras
        },
        ticks: {
          color: '#000', // Cor das etiquetas do eixo X
        },
      },
      y: {
        grid: {
          color: '#e0e0e0', // Linhas da grade vertical mais claras
        },
        ticks: {
          stepSize: 1000,
          color: '#000', // Cor das etiquetas do eixo Y
        },
        min: 0,
        max: 5000,
      },
    },
  };

  return (
    <div
      style={{
        width: '100%',
        minHeight: '500px', // Altura aumentada
        backgroundColor: '#fff', // Fundo cinza
        padding: '20px',
        borderRadius: '10px',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Sombra leve
      }}
    >
      <Line data={data} options={options} />
    </div>
  );
};

export default LineChartExample;
