import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_EVALUATION_METHOD_BY_ID, GET_EVALUATION_METHODS_BY_COMPANY_ID } from "../../evaluation/mutation.gql";
import { Section } from "../section";
import VerticalLinearStepper from "../sections/create_indicator_stepper";
import ContentStepper from "../sections/indicateContent";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const Header = ({handleScoreChange, handleStep, step, hero, evaluations, setEvaluations }) => {
  const { methodId } = useParams();
  
  const [getMethodById, { data, loading, error }] = useLazyQuery(GET_EVALUATION_METHOD_BY_ID);


  useEffect(() => {
    if (methodId) {
      getMethodById({ variables: { methodId } });
    }
  }, [methodId, getMethodById]);
 

  if (loading) return <p>Carregando...</p>;
  if (error) return <p>Erro: {error.message}</p>;
  
  return (
    <>
      <VerticalLinearStepper
       setEvaluations={setEvaluations}
       evaluations={evaluations}
        step={step}
        methods={data?.evaluationMethod?.data || {}}
        handleStep={handleStep}
        handleScoreChange={handleScoreChange}
      />
    </>

  );
};

const Content = ({evaluations,setEvaluations,  matchStep, eval_period, matchSteps, indicate, handleStepClick, step, handleStep, matchStepOpen, hero, company_id, setCompany_id, companyEditEval, setCompanyEditEval, btnState, setBtnState, answer_Id, question_Id, score_Id, set_Answer_Id, set_Score_Id, set_Question_Id, showEvals, setShowEvals, closedAnsID, setStep, scoreFilter, setScoreFilter }) => {

  return (
    <>
      <ContentStepper
      evaluations={evaluations} setEvaluations={setEvaluations}  
        eval_period={eval_period}
        step={step}
        indicate={indicate}
        handleStepClick={handleStepClick}
        handleStep={handleStep}
        matchStepOpen={matchStepOpen}
        hero={hero}
        company_id={company_id}
        setCompany_id={setCompany_id}
        btnState={btnState}
        setBtnState={setBtnState}
        showEvals={showEvals}
        setShowEvals={setShowEvals}
        closedAnsID={closedAnsID}
        companyEditEval={companyEditEval}
        setCompanyEditEval={setCompanyEditEval}
        setStep={setStep}
        scoreFilter={scoreFilter}
        setScoreFilter={setScoreFilter}
      />
    </>
  );
};

export const Indicate = ({ handleOpen, eval_period, indicators, collaborator, company_id, setCompany_id, btnState, setBtnState, showEvals, setShowEvals, companyEditEval, setCompanyEditEval, scoreFilter, setScoreFilter, loading, setLoading }) => {
  const [step, setStep] = useState(0);
  const [evaluations, setEvaluations] = useState([]);


  const handleStep = (stepIndex) => {
    setStep(stepIndex);
  };

  const [matchStep, setMatchStep] = useState(null);
  const [matchSteps, setMatchSteps] = useState([]);

  const handleScoreChange = (criterionId, score) => {
    setEvaluations((prevEvaluations) => {
      const updatedEvaluations = {
        ...prevEvaluations,
        criteria: prevEvaluations.criteria.map((criterion) =>
          criterion.id === criterionId
            ? { ...criterion, score } // Atualiza a pontuação para o critério específico
            : criterion // Mantém o critério sem alterações se o ID não for igual
        ),
      };
  
      return updatedEvaluations; // Retorna a nova estrutura de avaliações
    });
  };
  


  return (
    <Section
      header={<Header handleScoreChange={handleScoreChange} evaluations={evaluations} setEvaluations={setEvaluations}  handleStep={handleStep} hero={collaborator} step={step} />}
      content={
        <Content
        evaluations={evaluations} setEvaluations={setEvaluations}  
          eval_period={eval_period}
          setCompany_id={setCompany_id}
          company_id={company_id}
          handleStep={handleStep}
          hero={collaborator}
          matchSteps={matchSteps}
          step={step}
          btnState={btnState}
          setBtnState={setBtnState}
          showEvals={showEvals}
          companyEditEval={companyEditEval}
          setCompanyEditEval={setCompanyEditEval}
          setShowEvals={setShowEvals}
          setStep={setStep}
          scoreFilter={scoreFilter}
          setScoreFilter={setScoreFilter}
          loading={loading}
          setLoading={setLoading}
        />
      }
    />
  );
};
