const WelcomeCard = ({ title, subtitle, action }) => {
  return (
    <>
      <div className="bg-white rounded-[10px] amazing-shadow p-4 w-full">
        <div className="flex flex-col w-full">
          <small
            className="block w-full text-el-1"
            style={{
              fontWeight: 'bold',
              fontSize: 15,
            }}
          >{title}</small>
          <small className="text-gray-600 w-full">{subtitle}</small>
          <div className="flex items-center w-full mt-4 gap-1.5">
            <a
              href="/profile"
              className="bg-[#F89220] text-white py-[0.5rem] px-[0.75rem] rounded-[0.25rem] text-sm"
            >Ver Perfil</a>
            <a
              href="/password/change"
              className="bg-transparent text-[#F89220] py-[0.5rem] px-[0.75rem] rounded-[0.25rem] text-sm"
            >Alterar Senha</a>
          </div>
        </div>
      </div>
    </>
  );
};

export default WelcomeCard;