import { Card, CardActions, CardContent, CardHeader } from "@mui/material";

const MainCard = ({ title, subtitle, headerAction, action, children }) => {
  return (
    <Card elevation={1} style={{ borderRadius: "0.625rem", marginBottom: "2.5rem", height: "100%" }}>
      <CardHeader
        title={title}
        titleTypographyProps={{ variant: "h6", fontSize: "1rem" }}
        subheader={subtitle}
        subheaderTypographyProps={{ variant: "body2", fontSize: "0.8rem" }}
        action={headerAction}
      />
      {children && <CardContent>{children}</CardContent>}
      <CardActions>{action}</CardActions>
    </Card>
  );
};

export default MainCard;
