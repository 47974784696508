import { Edit, NoteAlt } from "@mui/icons-material";
import i18n from "../../../../translate/i18n";

const Summary = ({ intro, lng }) => {
  return (
    <>
      <div className="">
        <h4 className="curv-title mb-2 flex items-center gap-2">
          <span className="self-center flex items-center justify-center text-sm augmented-top"><NoteAlt /></span>
          <span className="self-center"> {i18n.t('cv.summary', { lng: lng })} </span>
        </h4>
        <small className="block">{intro}</small>
      </div>
    </>
  );
};

export default Summary;