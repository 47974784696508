import { useParams } from "react-router-dom";
import { GET_EVALUATION_METHODS_BY_COMPANY_ID } from "../../evaluation/mutation.gql";
import { Box, Card, CardContent, CircularProgress, Grid, Typography } from "@mui/material";
import { useLazyQuery } from "@apollo/client";
import { useEffect } from "react";

export default function MethodsMain(){
  const { companyId } = useParams();
    
    const [getMethodByIdCompany, { data, loading, error }] = useLazyQuery(GET_EVALUATION_METHODS_BY_COMPANY_ID);
  
  
    useEffect(() => {
      if (companyId) {
        getMethodByIdCompany({ variables: { companyId } });
      }
    }, [companyId, getMethodByIdCompany]);

    if (loading) {
        return <CircularProgress />;
      }
    
      if (error) {
        return <Typography color="error">Erro ao carregar os métodos.</Typography>;
      }
    
      return (
        <Box sx={{ padding: "16px" }}>
          <Typography variant="h6" gutterBottom>
           Meus Métodos de Avaliação
          </Typography>
          
          <Grid container spacing={3}>
            {data?.evaluationMethods?.data?.map((method) => (
              <Grid item xs={12} sm={6} md={4} key={method.id}>
                <Card>
                  <CardContent>
                    {/* Nome do método */}
                    <Typography variant="h6" fontWeight="bold" gutterBottom>
                      {method.attributes.name}
                    </Typography>
                    
                    {/* Critérios do método */}
                    <Box sx={{ marginTop: "16px" }}>
                      {method.attributes?.criteria?.data?.map((criterion) => (
                        <Box key={criterion.id} sx={{ marginBottom: "12px", display: "flex", justifyContent: "space-between" }}>
                          <Typography variant="body1" fontWeight="medium">
                            {criterion.attributes.name}
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            {criterion.attributes.percentage}% 
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      );
    };