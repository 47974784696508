

const CardService = ({ imgUrl, service }) => {
  return (
    <>
      <div className="flex border h-[260px] rounded-[0.25rem] relative">
        <img src={imgUrl} style={{ width: '100%', objectFit: 'cover', height: '100%', borderRadius: '4px' }} />
        <span style={{ zIndex: '11111' }} className="absolute bottom-2 w-[90%] text-center left-[50%] -translate-x-[50%] mb-8 block bg-[#DDAE98] text-white font-bold p-2 rounded-[0.25rem]">{service}</span>
      </div>
    </>
  );
};

export default CardService;