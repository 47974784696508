import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';

const PieChartAlt = ({ evalMethods, collaborators }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [popupData, setPopupData] = useState(null); // Para armazenar os dados do popup
  const [state, setState] = useState({
    series: [],
    options: {
      chart: {
        width: 380,
        type: 'pie',
        events: {
          dataPointSelection: (event, chartContext, { dataPointIndex }) => {
            // Recupera os dados clicados
            const selectedCategory = state.options.labels[dataPointIndex];
            const selectedValue = state.series[dataPointIndex];
            const relatedData = evalMethods[dataPointIndex];

            // Define os dados do popup
            setPopupData({
              category: selectedCategory,
              value: selectedValue,
              totalCriteria: relatedData?.attributes?.criteria?.data?.length || 0,
              description: relatedData?.attributes?.description || 'Sem descrição',
            });
          },
        },
      },
      labels: [], // Labels dinâmicas
      legend: {
        position: 'bottom',
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: '100%',
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    },
  });

  useEffect(() => {
    if (evalMethods && evalMethods.length > 0) {
      setIsVisible(true);

      const categories = evalMethods.map((item) => item?.attributes?.name);
      const values = evalMethods.map((item) => item?.attributes?.criteria?.data?.length || 0);

      console.log("RRRRRRR", evalMethods);
      console.log("RRRRRRRCCCCC", collaborators);

      setState((prevState) => ({
        ...prevState,
        series: values,
        options: {
          ...prevState.options,
          labels: categories,
        },
      }));
    }
  }, [evalMethods]);

  return (
    <div className="card">
      <div id="chart" className="flex items-center justify-center">
        {isVisible && (
          <ReactApexChart
            options={state.options}
            series={state.series}
            type="pie"
            width={380}
          />
        )}
      </div>

      {/* Popup para exibir mais informações */}
      {popupData && (
        <div
          className="popup-overlay"
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 1000,
          }}
          onClick={() => setPopupData(null)} // Fecha o popup ao clicar fora
        >
          <div
            className="popup-content"
            style={{
              backgroundColor: '#fff',
              padding: '20px',
              borderRadius: '8px',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
              textAlign: 'center',
              maxWidth: '400px',
            }}
          >
            <h3>Informações Detalhadas</h3>
            {/* <p><strong>Categoria:</strong> {popupData.category}</p>
            <p><strong>Valor:</strong> {popupData.value}</p> */}
            <p><strong>Total de Critérios:</strong> {popupData.totalCriteria}</p>
            <p><strong>Descrição:</strong> {popupData.description}</p>
            <button
              onClick={() => setPopupData(null)}
              style={{
                marginTop: '10px',
                padding: '10px 20px',
                backgroundColor: '#F89220',
                color: '#fff',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer',
              }}
            >
              Fechar
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PieChartAlt;
