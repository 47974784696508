const CardItem = ({ icon, title, link }) => {
  console.log("LINK: ", link);
  return (
    <>
      <a
        href={link}
        title={title}
        style={{ alignItems: 'center' }} className="flex bg-white shadow-md border px-4 py-5 rounded-[0.25rem]">
        <div className="w-[40px] self-start pointer-events-none">
          {icon}
        </div>
        <div
          style={{
            alignItems: 'flex-start',
            justifyContent: 'flex-start'
          }}
          className="flex flex-col pointer-events-none">
          <span style={{
            fontWeight: 800, fontSize: '0.8rem',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            // whiteSpace: 'nowrap',
            maxWidth: '140px',
            maxHeight: '40px',
            display: 'block'
          }}>
            {title}
          </span>
          <small className="hidden">Lorem ipsum dolor</small>
        </div>
      </a>
    </>
  );
};

export default CardItem;