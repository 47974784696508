import { Psychology } from "@mui/icons-material";
import i18n from "../../../translate/i18n";
import { useState } from "react";

const Skills = ({ skills, lng }) => {

  const [percentualText, setPercentualText] = useState('');

  const percentualAnalysis = (percent) => {
    if (percent <= 45) {
      return 'Normal';
    }
    if (percent > 45 && percent <= 70) {
      return 'Intermediario';
    }
    if (percent > 70) {
      return 'Qualificado';
    }
  };


  return (
    <>
      <div className="">
        <h4 className="curv-title mb-4 item-center flex gap-2">
          <span className="augmented-top"><Psychology /></span>
          {i18n.t('cv.skill', { lng: lng })}
        </h4>
        <div className="flex flex-wrap w-full gap-2">
          {
            skills?.map((item, index) => (
              <div key={index} className="flex flex-col text-white flex-wrap w-full justify-around gap-16">

                <div className="w-full curv-lang flex gap-10 item-center">
                  <div className="flex flex-col self-start w-full">
                    <small className="w-full block self-start">{item.name}</small>
                  </div>
                  <div className="flex flex-col mt-2">
                    <div className="curv-bubbles flex items-center gap-1">
                      {
                        Array.from({ length: 5 }).map((_, index) => (
                          <span
                            key={index}
                            className={`bubble ${index < Math.ceil((item.percentual ?? 40) / 20) ? 'active' : ''}`}
                          ></span>
                        ))
                      }
                    </div>
                    <small className="self-end mt-1 text-gray-100">{item.percentual ?? 40}%</small>
                  </div>

                </div>
              </div>
            ))
          }
        </div>

      </div>
    </>
  );
};

export default Skills;