import { School } from "@mui/icons-material";
import Section from "./Section";
import { useEffect, useState } from "react";
import i18n from "../../../../translate/i18n";

const Education = ({ edu, lng }) => {
  return (
    <>
      <Section icon={<School />} title={i18n.t('cv.education', { lng: lng })} element={<Academic edu={edu} />} />
    </>
  );
};

const Academic = ({ edu }) => {


  return (
    <>
      {
        edu?.map((item, index) => (
          <div
            key={index}
            className="flex flex-wrap items-center w-full gap-6"
          >
            <div className="w-[20%] self-stretch">
              <div className="institution-item h-full flex flex-col">
                <small className="font-bold">
                  {item.startDate.split('-')[0]} - {item.endDate.split('-')[0]}
                </small>
                <small className="text-gray-500">{item.country}</small>
              </div>
            </div>
            <div className="w-[70%] self-start">
              <h4 className="curv-inner-title">{item.course}</h4>
              <span className="-mt-1 block">{item.faculty}</span>
              <ul className="text-gray-600 mt-4 pl-5 flex flex-col w-full gap-4">
                <li>{item.description ?? 'No description'}</li>
              </ul>
            </div>
          </div>
        ))
      }
    </>
  );
};

export default Education;