import {
    Autocomplete,
    Button, Grid, InputLabel, TextField, Typography, createFilterOptions
} from "@mui/material";
import { useEffect, useState } from "react";
import { useMutation, useLazyQuery } from "@apollo/client";
import { toast } from "react-toastify";
import { GET_IT_LANGUAGES } from "../query.gql";
import { CREATE_ILANGUAGE, CREATE_LANGUAGE, UPDATE_LANGUAGE } from "../mutation.gql";
import i18n from "../../../../translate/i18n";
import { LanguageContext } from "../../../../translate/languages/LanguageContext";
import { useContext } from "react";




const LanguageStep = ({
    handleClose,
    collaboratorID,
    collaborator,
    setCollaborator,
    collaborators,
    setCollaborators,
    ObjectList,
    setObjectList,
    step,
    stepID,
    setLanguage,
    setIsGridVisible

}) => {
    const { selectedLanguage } = useContext(LanguageContext);
    const addStep = step?.includes("Add");
    const editStep = step?.includes("Edit");
    const createStep = step?.includes("Create");
    const filter = createFilterOptions();
    const jwt = localStorage.getItem("jwtToken");
    const [getItToolsName] = useLazyQuery(GET_IT_LANGUAGES);
    const [update_language] = useMutation(UPDATE_LANGUAGE);
    const [new_itLanguages] = useMutation(CREATE_ILANGUAGE);
    const [new_language] = useMutation(CREATE_LANGUAGE);
    //const [skill, setSkill] = useState([])
    const [itTools, setItTools] = useState([]);
    const [newItTools, setNewItTools] = useState([]);
    const [itToolValue, setItToolValue] = useState(null);
    const [itToolError, setItToolError] = useState(null);
    const [level, setLevel] = useState(null);

    const levelOptions = [
        { id: "Beginner", name: "Beginner" },
        { id: "Intermediate", name: "Intermediate" },
        { id: "Advanced", name: "Advanced" },
    ];


    useEffect(() => {
        setItTools([]);
        getItToolsName({
            context: {
                headers: {
                    authorization: `Bearer ${jwt}`,
                },
            },
            //fetchPolicy: "network-only",
        }).then((data) => {

            
             const itToolsData = data.data?.languages?.data?.map((item) => {
                  return {
                      id: item.id,
                      name: item.attributes.idioma,
                  };
              });
  
              setItTools(itToolsData); 

        });
        if (step?.includes("Edit")) {
               const foundItTool = ObjectList.find((object) => object.id === stepID);
                 setItToolValue({ id: foundItTool.languageId, name: foundItTool.language })
                 setLevel(foundItTool.level) 
        }
    }, []);

    const handleInputChange = (event, setVariable) => {
        setVariable(event.target.value);
    };

    const handleAdd = () => {
        if (itToolValue === null) {
            setItToolError(i18n.t('collaboratorStep.error.LanguageSelect', { lng: selectedLanguage }));

        } else {

            console.log("payload", itToolValue.id, level.id, collaboratorID );
            
            new_language({
                context: {
                    headers: {
                        authorization: `Bearer ${jwt}`,
                    },
                },
                //fetchPolicy: "network-only",
                variables: {
                    language: itToolValue.id, 
                    level: level.id, 
                    collaborator: collaboratorID, 
                  }
            }).then((data) => {
                console.log("language added", data);
                
                 const newLanguage = data.data.createLanguageCollaborator.data
                const updatedData = {
                    id: newLanguage.id,
                    level: newLanguage.attributes.level,
                    language: newLanguage.attributes.language.data.attributes.idioma,
                    languageId: newLanguage.attributes.language.data.id
                }
                const updatedMainData = collaborators?.map(obj => {
                    if (obj.id === collaborator.id) {
                        return { ...obj, language: [...obj.language, updatedData] }; // Add the updatedData value to the expertise array
                    }
                    return obj; // Keep the original object
                });
                const newList = [...ObjectList, updatedData]
                setObjectList(newList)
                setCollaborators(updatedMainData) 
                handleClose()
                setItToolValue(null)
                setItToolError(null)
                setLevel(null)
                toast.success("Language Done!!")
            })


        }

    };
    const handleEdit = () => {
        if (itToolValue === null) {
            setItToolError(i18n.t('collaboratorStep.error.LanguageSelect', { lng: selectedLanguage }));

        } else {
            update_language({
                context: {
                    headers: {
                        authorization: `Bearer ${jwt}`,
                    },
                },
                //fetchPolicy: "network-only",
                variables: {
                    id: stepID,
                    data: {
                      
                    },
                },
            }).then((data) => {
                const foundLanguage=
                {
                  id: stepID,
                  level: level.id,
                  language: itToolValue.name,
                  languageId:itToolValue.id
                }
               const foundLanguageIndex = ObjectList.findIndex(language => language.id === stepID);

                if (foundLanguageIndex !== -1) {
                    ObjectList[foundLanguageIndex] = {
                        ...foundLanguage,
                    };
                } 
                handleClose()
                setItToolValue(null)
                setItToolError(null)
                setLevel(null)
                toast.success("Language Updated!!")
            })


        }

    };
    const handleCreate = () => {
        if (itToolValue === null) {
            setItToolError(i18n.t('collaboratorStep.error.LanguageSelect', { lng: selectedLanguage }));

        } else {
           
           const newLanguage = {
            language: itToolValue.name,
                languageId: itToolValue.id,
                level: level.id
            }; 
            setLanguage(prevState => [...prevState, newLanguage]);
        
            setIsGridVisible(false);

        }

    };



    return (


        <>

            <Grid container item xs={12} spacing={3} id="expGrid">
                <Grid item xs={12} md={6}>
                    <InputLabel >{i18n.t('collaboratorStep.collaborator.nameLanguage', { lng: selectedLanguage })}</InputLabel>
                    <Autocomplete
                        value={itToolValue}
                        onChange={(event, newValue) => {
                            if (typeof newValue === 'string') {
                                const filteredItTools = itTools?.filter(item =>
                                    item.name?.toLowerCase() === newValue?.toLowerCase()
                                );

                                if (filteredItTools.length > 0) {
                                    setItToolValue({
                                        name: filteredItTools[0].name,
                                        id: filteredItTools[0].id
                                    });
                                    setItToolError(null)
                                } else {
                                    console.log("newValue.inputValue", newValue.inputValue);

                                    new_itLanguages({
                                        context: {
                                            headers: {
                                                authorization: `Bearer ${jwt}`,
                                            },
                                        },
                                        //fetchPolicy: "network-only",
                                        variables: {
                                            data: { 
                                              idioma: newValue.inputValue,
                                            },
                                          },
                                        
                                    }).then((data) => {
                                        console.log("data", data);
                                        const itToolId = data.data.createLanguage.data.id;
                                        const itToolName = data.data.createLanguage.data.attributes.idioma;
             
                                                     setItToolValue({
                                                         name: itToolName, id: itToolId
                                                     });
                                                     setItToolError(null)
                                                     const newOption = { name: itToolName, id: itToolId }
             
                                                     setNewItTools([...newItTools, newOption]); 
                                    }).catch((err) => {
                                        toast.error("Ocorreu um erro ao salvar idioma")
                                        console.log("err", err);

                                    })
                                }

                            } else if (newValue && newValue.inputValue) {
                                // Create a new value from the user input
                                new_itLanguages({
                                    context: {
                                        headers: {
                                            authorization: `Bearer ${jwt}`,
                                        },
                                    },
                                    //fetchPolicy: "network-only",
                                    variables: {
                                        data: { 
                                          idioma: newValue.inputValue,
                                        },
                                      },
                                    
                                }).then((data) => {
                                    console.log("data", data);

                                        const itToolId = data.data.createLanguage.data.id;
                                       const itToolName = data.data.createLanguage.data.attributes.idioma;
   
                                       setItToolValue({
                                           name: itToolName, id: itToolId
                                       });
                                       setItToolError(null)
                                       const newOption = { name: itToolName, id: itToolId }
   
                                       setNewItTools([...newItTools, newOption]); 
                                }).catch((err) => {
                                    toast.error("Ocorreu um erro ao salvar idioma")
                                    console.log("err", err);

                                })
                            } else {
                                setItToolValue(newValue);
                                setItToolError(null)
                            }
                        }}
                        filterOptions={(options, params) => {
                            const filtered = filter(options, params);

                            const { inputValue } = params;
                            // Suggest the creation of a new value
                            const isExisting = options.some((option) => inputValue === option.name);
                            if (inputValue !== '' && !isExisting) {
                                filtered.push({
                                    inputValue,
                                    name: `Add "${inputValue}"`,
                                });
                            }

                            return filtered;
                        }}
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        id="eduFaculty"
                        options={[...itTools, ...newItTools]}
                        getOptionLabel={(option) => {
                            // Value selected with enter, right from the input
                            if (typeof option === 'string') {
                                return option;
                            }
                            // Add "xxx" option created dynamically
                            if (option.inputValue) {
                                return option.inputValue;
                            }
                            // Regular option
                            return option.name;
                        }}
                        renderOption={(props, option) => <li {...props}>{option.name}</li>}
                        size="small"
                        freeSolo
                        renderInput={(params) => (
                            <TextField {...params} />
                        )}
                    />
                    {itToolError && <Typography
                        variant="body2"
                        color="gray"
                        style={{ fontSize: '12px', marginTop: '5.5px', marginLeft: '14px' }}>
                        {itToolError}
                    </Typography>}
                </Grid>

                <Grid item xs={12} md={6}>
                    <InputLabel >{i18n.t('collaboratorStep.collaborator.level', { lng: selectedLanguage })}</InputLabel>

                    <Autocomplete
                        size="small"
                        required
                        autoFocus
                        options={levelOptions}
                        getOptionLabel={(option) => option.name}
                        value={level}
                        onChange={(event, newValue) => {
                            setLevel(newValue);
                        }}
                        fullWidth
                        placeholder={i18n.t('collaboratorStep.collaborator.levelBody', { lng: selectedLanguage })}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                name="skillPercentual"
                                id="skillPercentual"
                                placeholder={i18n.t('collaboratorStep.collaborator.levelBody', { lng: selectedLanguage })}
                            />
                        )}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2} marginTop={1}>
                <Grid container item xs={12} spacing={3} marginLeft={0.5} >
                    {createStep &&
                        <Grid item>
                            <Button
                                type="button"
                                color="primary"
                                onClick={handleCreate}
                            >
                                {i18n.t('collaboratorStep.button.language', { lng: selectedLanguage })}
                            </Button>
                        </Grid>
                    }
                </Grid>
                <Grid container spacing={3} item xs={12} display="flex" justifyContent="space-around" alignItems="center">
                    {!createStep && <Grid item>
                        <Button variant="outlined" size="small" onClick={handleClose}>{i18n.t('collaboratorStep.button.cancel', { lng: selectedLanguage })}</Button>
                    </Grid>}
                    <Grid item>

                        {editStep && <Button
                            variant="contained"
                            size="small"
                            onClick={
                                handleEdit

                            }
                        >{i18n.t('collaboratorStep.button.edit', { lng: selectedLanguage })}</Button>}
                       
                        {addStep && <Button
                            variant="contained"
                            size="small"
                            onClick={
                                handleAdd

                            }
                        >{i18n.t('collaboratorStep.button.create', { lng: selectedLanguage })}</Button>}
                    </Grid>
                </Grid>
            </Grid>

        </>
    );

};

export default LanguageStep;