import { DynamicFeed, HomeRepairService, Psychology } from "@mui/icons-material";
import Section from "./Section";
import i18n from "../../../../translate/i18n";

const exp1 = [
  {
    startDate: '2020',
    endDate: '',
    company: 'Newark, New Jersy',
    role: 'Virtual Europass CV Manager',
    postRole: 'Create your CV',
    function: 'Recruitment Managment',
    task: [
      'Collaborate with HR managers to edit Europass CV and so on',
      'nsectetur adipisicing elit. Commodi magni suscipit eum odit iure dicta quis reprehenderit ut voluptatum nesciunt nisi iste,',
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempora vel adipisci dicta!'
    ]
  },
  {
    startDate: '2016',
    endDate: '2020',
    company: 'Sintaxy, Luanda Angola',
    role: 'System Analyst',
    postRole: 'Analysis of TI',
    function: '',
    task: [
      'Collaborate with HR managers to edit Europass CV and so on',
      'nsectetur adipisicing elit. Commodi magni suscipit eum odit iure dicta quis reprehenderit ut voluptatum nesciunt nisi iste,',
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempora vel adipisci dicta!'
    ]
  },
];

const Experience = ({ exp, lng }) => {
  return (
    <>
      <Section icon={<HomeRepairService />} title={i18n.t('cv.experience', { lng: lng })} element={<Expert exp={exp} />} />
    </>
  );
};

const Expert = ({ exp }) => {
  return (
    <>
      {
        exp?.map((item, index) => (
          <div
            key={index}
            className="flex items-center w-full gap-6"
          >
            <div className="w-[20%] self-stretch">
              <div className="institution-item h-full flex flex-col">
                <span className="font-bold">
                  {item.startDate.split('-')[0]}{" - "}{item.endDate == null || item.endDate.length == 0 ? 'Present' : item.endDate.split('-')[0]}
                </span>
                <small className="text-gray-500">{item.expEntity ?? 'N/A'}, {item.country}</small>
              </div>
            </div>
            <div className="w-[70%] self-start">
              <h4 className="curv-inner-title">{item.postRoleDescription ?? 'N/A'}</h4>
              <span className="-mt-1 block">{item.postRole ?? 'N/A'}</span>
              <small className="text-gray-500">{item.function}</small>
              <ul className="text-gray-600 mt-4 pl-5 flex flex-col w-full gap-4">
                <li>{item.description.length == 0 ? 'No description' : item.description}</li>
                {
                  item?.task?.map((t, i) => (
                    <li key="i">{t}</li>
                  ))
                }
              </ul>
            </div>
          </div>
        ))
      }
    </>
  );
};

export default Experience;