import { gql } from "@apollo/client";

export const LOGIN = gql`
  mutation Login($input: UsersPermissionsLoginInput!) {
    login(input: $input) {
      jwt
      user {
        id
        email
        
      }
    }
  }
`;
export const REGISTER = gql`
mutation Register($input: UsersPermissionsRegisterInput!) {
  register(input: $input) {
    jwt
    user {
      id
      email
    }
  }
}
`;


export const CREATE_COMPANY = gql`
mutation CreateCompany($data: CompanyInput!) {
  createCompany(data: $data) {
    data {
      id
      attributes {
        name
        email
      }
    }
  }
}

`;
export const CREATE_COLLABORATOR = gql`
mutation CreateCollaborator($data: CollaboratorInput!) {
  createCollaborator(data:$data) {
    data {
      id
      attributes {
        name
        email
      }
    }
  }
}

`;


export const CREATE_USER = gql`
mutation CreateUser($data: UsersPermissionsUserInput!) {
  createUsersPermissionsUser(data: $data){data{id attributes{username email confirmed }}}
}
`;


export const UPDATE_USER_PASSWORD = gql`
  mutation Mutation(
    $updateUsersPermissionsUserId: ID!
    $data: UsersPermissionsUserInput!
  ) {
    updateUsersPermissionsUser(id: $updateUsersPermissionsUserId, data: $data) {
      data {
        attributes {
          email
        }
      }
    }
  }
`;

export const UPDATE_EXPERIENCE = gql`
mutation updateExperience($id:ID!,$data: ExperienceInput!) {
  updateExperience(id:$id,data: $data) {
    data {
      id
      attributes {
        expEntity{data{id attributes{name}}}
        startDate
        endDate
        country{data{id attributes{name}}}
        postRole{data{id attributes{postRole}}}
        description
      }
    }
  }
}

`;
export const CREATE_EXPERIENCE = gql`
mutation CreateExperience($data: ExperienceInput!) {
  createExperience(data: $data) {
    data {
      id
      attributes {
        expEntity{data{id attributes{name}}}
        startDate
        endDate
        country{data{id attributes{name}}}
        postRole{data{id attributes{postRole}}}
        description
      }
    }
  }
}`;
export const DELETE_EXPERIENCE = gql`
mutation deleteExperience($id: ID!) {
  deleteExperience(id:$id) {
    data {
      id
      attributes {
        description
      }
    }
  }
}

`;

export const CREATE_EDUCATION = gql`
mutation CreateEducation($data: EducationInput!) {
  createEducation(data: $data) {
    data {
      id
      attributes {
        course{data{id attributes{name}}}
        faculty{data{id attributes{name}}}
        description
        endDate
        startDate
        country{data{id attributes{name}}}
        collaborator{data{id attributes{name}}}
      }
    }
  }
}

`;
export const UPDATE_EDUCATION = gql`
mutation UpdateEducation($id:ID!,$data: EducationInput!) {
  updateEducation(id:$id,data:$data) {
    data {
      id
      attributes {
        course{data{id attributes{name}}}
        faculty{data{id attributes{name}}}
      }
    }
  }
}

`;
export const DELETE_EDUCATION = gql`
mutation deleteEducation($id: ID!) {
  deleteEducation(id:$id) {
    data {
      id
      attributes {
        description
      }
    }
  }
}

`;
export const UPDATE_PROFILE = gql`
mutation updateProfile($id:ID!,$data: ProfileInput!) {
  updateProfile(id:$id,data: $data) {
    data {
      id
      attributes {
        urlProfile
description
platform{data{id attributes{name icon{data{id attributes{name url}}}}}}
      }
    }
  }
}
`;
export const CREATE_PROFILE = gql`
mutation CreateProfile($data: ProfileInput!) {
  createProfile(data: $data) {
    data {
      id
      attributes {
        platform{data{id attributes{name icon{data{id attributes{name url}}}}}}
        urlProfile
description
      }
    }
  }
}
`;
export const DELETE_PROFILE = gql`
mutation deleteProfile($id: ID!) {
  deleteProfile(id:$id) {
    data {
      id
      attributes {
        description
      }
    }
  }
}

`;
export const CREATE_PROFILE_PLATFORM = gql`
mutation CreatePlatform($data: ProfilePlatformInput!) {
  createProfilePlatform(data: $data) {
    data {
      id
      attributes {
        name
      }
    }
  }
}
`;
export const CREATE_AWARD = gql`
mutation CreateAward($data: AwardInput!) {
  createAward(data: $data) {
    data {
      id
      attributes {
        name
        description
      }
    }
  }
}

`;
export const UPDATE_AWARD = gql`
mutation updateAward($id:ID!,$data: AwardInput!) {
  updateAward(id:$id,data: $data) {
    data {
      id
      attributes {
        name
      }
    }
  }
}

`;
export const DELETE_AWARD = gql`
mutation deleteAward($id: ID!) {
  deleteAward(id:$id) {
    data {
      id
      attributes {
        description
      }
    }
  }
}
`;
export const CREATE_PORTFOLIO = gql`
mutation CreatePortfolio($data: PortfolioInput!) {
  createPortfolio(data: $data) {
    data {
      id
      attributes {
        name
        description
      }
    }
  }
}
`;
export const UPDATE_PORTFOLIO = gql`
mutation updatePortfolio($id: ID!,$data:PortfolioInput!) {
  updatePortfolio(id:$id,data:$data) {
    data {
      id
      attributes {
        name
      }
    }
  }
}
`;
export const DELETE_PORTFOLIO = gql`
mutation deletePortfolio($id: ID!) {
  deletePortfolio(id:$id) {
    data {
      id
      attributes {
        name
      }
    }
  }
}

`;

export const CREATE_EXP_ENTITY = gql`
mutation createExpEntity($data: ExpEntityInput!) {
  createExpEntity(data: $data) {
    data {
      id
      attributes {
        name
      }
    }
  }
}
`;

export const CREATE_EDUCATION_FACULTIES = gql`
mutation CreateEducationFaculties($data:EducationFacultyInput!) {
  createEducationFaculty(data: $data) {
    data {
      id
      attributes {
        name
      }
    }
  }
}

`;
export const CREATE_EDUCATION_COURSES = gql`
mutation CreateEducationCourses($data:EducationCourseInput!) {
  createEducationCourse(data: $data) {
    data {
      id
      attributes {
        name
      }
    }
  }
}

`;

export const CREATE_EXPERTISE = gql`
mutation CreateExpertise($data:ExpertiseInput!) {
  createExpertise(data: $data) {
    data {
      id
      attributes {
        name
      }
    }
  }
}

`;
export const CREATE_EXPERTISE_CATEGORY = gql`
mutation CreateExpertiseCategory($data:ExpertiseCategoryInput!) {
  createExpertiseCategory(data: $data) {
    data {
      id
      attributes {
        description
        expertise{data{id attributes{name}}}
      }
    }
  }
}
`;

export const CREATE_EXPERTISE_AREA = gql`
mutation CreateJobRoleColab($collaboratorId: [ID]!, $jobRoleId: [ID]!) {
  createJobroleColab(
    data: { 
      collaborators: $collaboratorId, 
      job_roles: $jobRoleId 
    }
  ) {
    data {
      id
      attributes {
        collaborators {
          data {
            id
          }
        }
        job_roles {
          data {
            id
          }
        }
      }
    }
  }
}
`;

export const UPDATE_EXPERTISE_CATEGORY = gql`
mutation updateExpertiseCategory($id: ID!,$data:ExpertiseCategoryInput!) {
  updateExpertiseCategory(id:$id,data:$data) {
    data {
      id
      attributes {
        description
        expertise{data{id attributes{name}}}
      }
    }
  }
}
`;
export const DELETE_EXPERTISE_CATEGORY = gql`
mutation deleteExpertiseCategory($id: ID!) {
  deleteExpertiseCategory(id:$id) {
    data {
      id
      attributes {
        description
      }
    }
  }
}

`;

export const UPLOAD_FILES = gql`
  mutation UploadFile($files: [Upload]!) {
    multipleUpload(files: $files) {
      data {
        id
        url
      }
    }
  }
`;


export const CREATE_ITTOOL = gql`
mutation CreateItTool($data:ItToolInput!) {
  createItTool(data: $data) {
    data {
      id
      attributes {
        name
      }
    }
  }
}

`;


export const CREATE_LANGUAGE = gql`
mutation createLanguageCollaborator($language: ID!, $level: ENUM_LANGUAGECOLLABORATOR_LEVEL!, $collaborator: ID!) {
  createLanguageCollaborator(data: {
    language: $language,
    level: $level,
    collaborator: $collaborator
  }) {
    data {
      id
      attributes {
        language {
          data {
            id
            attributes {
              idioma
            }
          }
        }
        level
        collaborator {
          data {
            id
            attributes {
              name
            }
          }
        }
}
}
  }
}

`;

export const UPDATE_LANGUAGE = gql`
mutation updateCollaboratorlanguage($id: ID!, $language: ID, $level: String, $collaborator: ID) {
  updateCollaboratorlanguage(id: $id, data: {
    language: $language,
    level: $level,
    collaborator: $collaborator
  }) {
    data {
      id
      attributes {
        language {
          data {
            id
            attributes {
              name
            }
          }
        }
        level
        collaborator {
          data {
            id
            attributes {
              name
            }
          }
        }
}
}
}
}
`;
export const CREATE_ILANGUAGE = gql`
mutation createLanguage($data: LanguageInput!) {
  createLanguage(data: $data) {
    data {
      id
      attributes {
        idioma
      }
    }
  }
}
`;
export const CREATE_SKILLS = gql`
mutation CreateSkills($data:SkillInput!) {
  createSkill(data: $data) {
    data {
      id
      attributes {
        percentual
        itTool{data{id attributes{name}}}
      }
    }
  }
}
`;
export const UPDATE_SKILLS = gql`
mutation UpdateSkills($id: ID!, $data: SkillInput!) {
  updateSkill(id: $id, data: $data) {
    data {
      id
      attributes {
        percentual
        itTool {
          data {
            id
            attributes {
              name
            }
          }
        }
      }
    }
  }
}

`;
export const DELETE_SKILLS = gql`
mutation deleteSkills($id: ID!) {
  deleteSkill(id:$id) {
    data {
      id
      attributes {
        percentual
      }
    }
  }
}

`;

export const CREATE_COUNTRIES = gql`
mutation($data: CountryInput!) {
  createCountry(data: $data) {
    data {
      id
      attributes {
        name
      }
    }
  }
}

`;
export const CREATE_CITIES = gql`
mutation($data: CityInput!) {
  createCity(data: $data) {
    data {
      id
      attributes {
        name
      }
    }
  }
}
`;

export const UPDATE_COLLABORATORS = gql`
mutation UpdateCollaborator($id:ID!,$data:CollaboratorInput!) {
  updateCollaborator(id: $id, data: $data) {
    data {
      id
      attributes {
        name
        email
        phone
        city{data{id attributes{name}}}
        country{data{id attributes{name}}}
        isActive
      }
    }
  }
}
`;

export const DELETE_COLLABORATORS = gql`
mutation DeleteCollaborator($id:ID!) {
  deleteCollaborator(id: $id) {
    data {
      id
      attributes {
        name
      }
    }
  }
}
`;
export const DELETE_USERS = gql`
mutation deleteUser($id:ID!) {
  deleteUsersPermissionsUser(id: $id) {
    data {
      id
      attributes {
        name
      }
    }
  }
}
`;
export const DISABLE_USERS = gql`
mutation disableUser($id:ID!,$disable:Boolean!) {
  updateUsersPermissionsUser(id: $id,data:{blocked:$disable}) {
    data {
      id
      attributes {
        name
      }
    }
  }
}
`;

export const UPDATE_CLIENTS = gql`
mutation updateClient($id: ID!,$data:ClientInput!) {
  updateClient(id:$id,data:$data) {
    data {
      id
      attributes {
        name
        collaborator{data{id attributes{name}}}
        companies{data{id attributes{name}}}}
    }
  }
}
`;
export const DELETE_MEDIA_FILE = gql`
mutation DeleteMediaLibrary($id: ID!) {
  deleteUploadFile(id: $id) {
    data {
      id
      attributes {
        name
      }
    }
  }
}

`;
export const CREATE_MEDIA_FILE = gql`
mutation uploadFile(
  $file: Upload!
  $info: FileInfoInput!
  $refId: ID!
  $field: String!
  $ref: String!
) {
  upload(file: $file, refId: $refId, field: $field, ref: $ref, info: $info) {
    data {
      id
      attributes {
        name
        url
      }
    }
  }
}

`;

export const ASSOC_CTOC = gql`
mutation updateCollaborator($id: ID!, $data: [ID]!) {
  updateCollaborator(id: $id, data: { companies: $data }) {
    data {
      id
      attributes {
        companies {
          data {
            id
            attributes {
              name
            }
          }
        }
      }
    }
  }
}
`;

export const ASSOC_C2C = gql`
  mutation updateCompany($id: ID!, $data: CompanyInput!) {
  updateCompany(id: $id, data: $data) {
    data {
      id
      attributes {
        status
        name
        users_permissions_user{data{id attributes{username}}} 
        city{data{id attributes{name}}}
        country{data{id attributes{name}}}
        email
        phone
        branchOfActivity
        image{data{id attributes{url}}}}
    }
  }
}

`;

export const TESTE_2 = gql`
mutation UpdateCollaboratorCompany($id: ID!, $companyId: [ID]!) {
  updateCollaborator(id: $id, data: { companies: $companyId }) {
    data {
      id
      attributes {
        name
        companies {
          data {
            id
            attributes {
              name
            }
          }
        }
      }
    }
  }
}
`;

// export const UPDATE_COMPANY_C = gql`
//   mutation updateCompany($id: ID!, $data: CompanyInput!) { 
//     updateCompany(id: $id, data: $data) { 
//       data { 
//         id 
//         attributes { 
//           collaborators { 
//             data { 
//               id 
//               attributes { 
//                 name 
//               } 
//             } 
//           } 
//         } 
//       } 
//     } 
//   }
// `;

export const UPDATE_COMPANY_C = gql`
  mutation updateCollaborator($id: ID!, $data: CollaboratorInput!) {
  updateCollaborator(id: $id, data: $data) {
    data {
      id
      attributes {
        companies {
          data {
            id
            attributes {
              name
            }
          }
        }
      }
    }
  }
}

`;

export const DELETE_NOTIFICATION_MUTATION = gql`
  mutation DeleteNotification($id: ID!) {
    deleteNotification(id: $id) {
      data {
        id
        attributes {
          action
          status
        }
      }
    }
  }
`;

export const CREATE_NOTIFICATION = gql`
  mutation CreateNotification(
    $status: Boolean,
    $action: ENUM_NOTIFICATION_ACTION,
    $collaboratorId: ID!,
    $companyId: ID!
  ) {
    createNotification(
      data: {
        status: $status
        action: $action
        Company: $companyId
        collaborator: $collaboratorId
      }
    ) {
      data {
        id
        attributes {
          action
          status
          Company {
            data {
              id
              attributes {
                name
              }
            }
          }
          collaborator {
            data {
              id
              attributes {
                name
              }
            }
          }
        }
      }  
    }
  }
`;


export const CREATE_EMPLOYMENT_COLLAB = gql`
  mutation CreateEmployment(
    $companyId: [ID!]!,
    $collaboratorId: [ID!]!,
    $position: String!,
    $startDate: Date!,
    $endDate: Date!,
    $status: ENUM_EMPLOYMENT_STATUS!,
    $Salary: Float!
  ) {
    createEmployment(
      data: {
        companies: $companyId
        collaborators: $collaboratorId
        position: $position
        startDate: $startDate
        endDate: $endDate
        status: $status
        Salary: $Salary
      }
    ) {
      data {
        id
        attributes {
          position
          startDate
          endDate
          status
          Salary
          companies {
            data {
              id
              attributes {
                name
              }
            }
          }
          collaborators {
            data {
              id
              attributes {
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const DELETE_EMPLOYMENT_BY_COLLABORATOR = gql`
  mutation DeleteEmployment($id: ID!) {
    deleteEmployment(id: $id) {
      data {
        id
        attributes {
          position
          startDate
          endDate
          status
          Salary
        }
      }
    }
  }
`;

export const CREATE_AVAL_PERIOD = gql`
  mutation CreateEvaluationPeriod(
    $start: Date!
    $end: Date!
    $isActive: Boolean!
    $description: String!
    $collaborators: [ID!]
    $companies: [ID!]
  ) {
    createEvaluationPeriod(data: {
      start: $start
      end: $end
      description: $description
      isActive: $isActive
      collaborators: $collaborators
      companies: $companies
    }) {
      data {
        id
        attributes {
          start
          end
          isActive
          description
          collaborators {
            data {
              id
              attributes {
                name
              }
            }
          }
          companies {
            data {
              id
              attributes {
                name
              }
            }
          }
        }
      }
    }
  }

`;

export const UPDATE_EMPLOYMENT_TO_NULL = gql`
  mutation UpdateEmploymentToNull($collaboratorId: ID!) {
    updateCollaborator(id: $collaboratorId, data: { employment: null }) {
      data {
        id
        attributes {
          name
          employment {
            data {
              id
            }
          }
        }
      }
    }
  }
`;
