import { useState, useRef, useContext, useEffect } from "react";
import { Grid, Typography, Card, CardActions, CardHeader, IconButton, Avatar, Box, Snackbar, Alert, AlertTitle } from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import i18n from '../../../translate/i18n';
import { LanguageContext } from '../../../translate/languages/LanguageContext';
import CloseIcon from '@mui/icons-material/Close';
import { theme } from '../../../components/Theme';
import useMediaQuery from '@mui/material/useMediaQuery';
import { makeStyles } from "@material-ui/core/styles";
import { AuthContext } from "../../../contexts/auth-context";
import WelcomeCard from "../../HomePage/cards/WelcomeCard";
import RadarChartWithColors from "../../HomePage/charts/RadarChart";
import AnalysisCard from "../../HomePage/cards/AnalysisCard";
import EvaluationHistoricCard from "../../HomePage/cards/EvaluationHistoricCard";
import LineChartExample from "../../HomePage/charts/LineChart";
import AvalWelcomeCard from "../../HomePage/cards/AvalWelcomeCard";
import { Star, StarOutline } from "@mui/icons-material";
import CollabItem from "./CollabItem";
import AnalysisCardClone from "../../HomePage/cards/AnalysisCardClone";
import MixedChart from "../../HomePage/charts/MixedChart";
import LineMixedChart from "../../HomePage/charts/LineMixedChart";
import { GET_COLLABORATORS_ALT } from "../../Collaborator/Collaborator_Creation_Page/query.gql";
import { useLazyQuery } from "@apollo/client";
import { GET_COMPANIES } from "../../CompanyPage/Company_Creation_Page/query.gql";
import { GET_ALL_EVALUATIONS_COLLABORATORS } from "../mutation";
const useStyles = makeStyles(theme => ({
  customHoverFocus: {
    backgroundColor: "#f8faf7",
    borderRadius: 5,
    padding: 4
  }
}));

const { REACT_APP_API_URL } = process.env;
function Ratings({

  companyId,
  setViewCollab,
  avaliars,
  eval_period,
  setCollabID,
  setBtnState,
  setShowEvals,
  setI_,
  adminViews,
  viewCollab,
  uRoleContext,
  eval_periodHistory
}) {
  const classes = useStyles();
  const menuRef = useRef(null);
  const { selectedLanguage } = useContext(LanguageContext);
  const isMobileMode = useMediaQuery(theme.breakpoints.up('lg'));
  const isMediumMode = useMediaQuery(theme.breakpoints.down('md'));
  const [display, setDisplay] = useState(-1);
  const [openTarget, setOpenTarget] = useState(false);
  const [infoMessage, setSInfoMessage] = useState("");
  const [evalId__, setEvalId__] = useState([])
  const [clickIndex, setClickIndex] = useState(false)
  const [eval_items, setEvalitems] = useState([])
  const [item_p, setItem_p] = useState([])
  const [openAlertOK, setOpenAlertOK] = useState(false)
  const [company_id, setCompany_id] = useState(null)
  const { user } = useContext(AuthContext);

  const [isAdmin, setIsAdmin] = useState(false);
  const [accountId, setAccountId] = useState(null);

  const jwt = localStorage.getItem("jwtToken");
  const [getCollaborators] = useLazyQuery(GET_COLLABORATORS_ALT);
  const [getBestCollaborators] = useLazyQuery(GET_ALL_EVALUATIONS_COLLABORATORS);
  const [collaboratorFilter, setCollaboratorFilter] = useState(null);
  const [loading1, setLoading] = useState(true);
  const [collaborators, setCollaborators] = useState([])

  const [companyCollaborators, setCompanyCollaborators] = useState([]);
  const [bestCollaborators, setBestCollaborators] = useState([]);
  const [currentCompanyCollaborators, setCurrentCompanyCollaborators] = useState([]);

  const role = localStorage.getItem('userRole') ?? '';
  const currentAccountID = localStorage.getItem('userId') ?? null;

  const [getCompanys_] = useLazyQuery(GET_COMPANIES);


  useEffect(() => {

    getCompanys_({
      context: {
        headers: {
          authorization: `Bearer ${jwt}`,
        },
      },
    }).then((data) => {
      setCompanyCollaborators(data?.data?.companies?.data);
    });
  }, []);

  useEffect(() => {

    getBestCollaborators({
      context: {
        headers: {
          authorization: `Bearer ${jwt}`,
        },
      },
    }).then((data) => {

      const evaluations = data?.data?.evaluations?.data;
      console.log("Avaliações recebidas", evaluations);

      // Criar um mapa para armazenar a soma de estrelas por colaborador
      const collaboratorScores = {};

      evaluations?.forEach((evaluation) => {
        const collaborator = evaluation.attributes.collaborator.data;


        if (collaborator) {
          const collaboratorId = collaborator.id;
          const collaboratorName = collaborator.attributes?.name;
          const star = evaluation.attributes.star || 0;
          const postRoleDescription = collaborator.attributes.postRole?.data?.attributes?.postRole ?? ""
          
          const avatar = collaborator.attributes.image?.data?.attributes?.url ?? ""
          // Somar os pontos de avaliações do colaborador
          if (!collaboratorScores[collaboratorId]) {
            collaboratorScores[collaboratorId] = {
              id: collaboratorId,
              name: collaboratorName,
              totalStars: 0,
              postRoleDescription: postRoleDescription,
              avatar: avatar
            };
          }

          collaboratorScores[collaboratorId].totalStars += star;
        }
      });

      // Converter o mapa em um array e ordenar pela pontuação total (descendente)
      const sortedCollaborators = Object.values(collaboratorScores).sort(
        (a, b) => b.totalStars - a.totalStars
      );

      // Selecionar os 3 melhores colaboradores
      const bestCollaborators = sortedCollaborators.slice(0, 3);

      console.log("Top 3 colaboradores", bestCollaborators);

      // Atualizar o estado com os melhores colaboradores
      setBestCollaborators(bestCollaborators);
    })
      .catch((error) => {
        console.error("Erro ao buscar avaliações", error);
      });
  }, []);


  useEffect(() => {
    if (role == 'administrator') {
      setIsAdmin(!isAdmin);
    }
    setAccountId(currentAccountID)
  }, [role, currentAccountID]);


  useEffect(() => {
    getCollaborators({
      context: {
        headers: {
          authorization: `Bearer ${jwt}`,
        },
      },
      //fetchPolicy: "no-cache",
    }).then((data) => {
      console.log("collabs", data?.data?.collaborators?.data);


      const collaboratorData = data?.data?.collaborators?.data?.map((item) => {
        return {
          id: item.id,
          userID: item.attributes?.users_permissions_user?.data?.id,
          name: item.attributes?.name,
          email: item.attributes?.email,
          phone: item.attributes?.phone,
          address: item.attributes?.address,
          web: item.attributes?.web,
          intro: item.attributes?.intro,
          country: item.attributes?.country?.data?.attributes?.name,
          countryID: item.attributes?.country?.data?.id,
          city: item.attributes?.city?.data?.attributes?.name,
          yearsofExperience: item.attributes?.yearsofExperience,
          speciality: item.attributes?.speciality,
          cityID: item.attributes?.city?.data?.id,
          postRole: item.attributes?.postRole?.data?.attributes?.postRole,
          postRoleID: item.attributes?.postRole?.data?.id,
          postRoleDescription: item.attributes?.postRole?.data?.attributes?.description,
          evaluations: item.attributes?.evaluations?.data,
          employmentCollab: item.attributes?.employment?.data,
          companies: item.attributes?.companies?.data/* ?.map((company) => {
                return {
                  companyID: company.id,
                  company: company.attributes?.name,
                };
              }) */,
          isActive: item.attributes?.isActive,
          avatar: item.attributes?.image?.data?.attributes?.url,
          avatarID: item.attributes?.image?.data?.id,
          /*   score: item.attributes?.evaluation_scores?.data?.map((score) => {
              return {
                id: score.id,
                //rateValue: score.attributes?.evaluation_answer_closeds?.data.map((score) => score.attributes.rate),
                rateLength: score.attributes?.evaluation_answer_closeds?.data.length,
                rateTotal: score.attributes?.evaluation_answer_closeds?.data.reduce((accumulator, currentScore) => accumulator + currentScore.attributes.rate, 0),
                company: score.attributes?.company?.data?.attributes?.name,
                companyID: score.attributes?.company?.data?.id
  
              };
            }), */
          profiles: item.attributes?.profiles?.data?.map((profiles) => {
            return {
              id: profiles.id,
              urlProfile: profiles.attributes?.urlProfile,
              description: profiles.attributes?.description,
              platform: profiles.attributes?.platform?.data?.attributes?.name,
              platformID: profiles.attributes?.platform?.data?.id,
              icon: profiles.attributes?.platform?.data?.attributes?.icon?.data?.attributes?.url

            };
          }),
          clientID: item.attributes?.client?.data?.id,
          client: item.attributes?.client?.data?.attributes?.companies?.data?.map((client) => {
            /* const matchingScores = item.attributes?.evaluation_scores?.data?.filter((score) => score.attributes?.company?.data?.id === client.id);
        const rateValue = matchingScores.flatMap((matchingScore) =>
            matchingScore?.attributes?.evaluation_answer_closeds?.data.map((score) => score.attributes.rate)
          );
          const rateLength = rateValue.length;
          const rateTotal = rateValue.reduce((accumulator, currentScore) => accumulator + currentScore, 0);
*/
            return {
              id: client.id,
              name: client.attributes?.name,
              image: client.attributes?.image?.data?.attributes?.url,
              /*   rateTotal: rateTotal,
                rateLength: rateLength,
                rateValue: rateValue, */
            };
          }),


          portfolios: item.attributes?.portfolios?.data?.map((portfolios) => {
            return {
              id: portfolios.id,
              name: portfolios.attributes?.name,
              description: portfolios.attributes?.description,
              image: portfolios.attributes?.image?.data?.attributes?.url,
              imageID: portfolios.attributes?.image?.data?.id
            };
          }),
          awards: item.attributes?.awards?.data?.map((profiles) => {
            return {
              id: profiles.id,
              name: profiles.attributes?.name,
              description: profiles.attributes?.description
            };
          }),
          skills: item.attributes?.skills?.data?.map((profiles) => {
            return {
              id: profiles.id,
              percentual: profiles.attributes?.percentual,
              name: profiles.attributes?.itTool?.data?.attributes?.name,
              itToolID: profiles.attributes?.itTool?.data?.id
            };
          }),
          expertise: item.attributes?.expertise?.data?.map((profiles) => {
            return {
              id: profiles.id,
              description: profiles.attributes?.description,
              expertise: profiles.attributes?.expertise?.data?.attributes?.name,
              expertiseID: profiles.attributes?.expertise?.data?.id
            };
          }),
          languages: item.attributes?.language_collaborators?.data?.map((lang) => {
            return {

              name: lang.attributes?.language.data.attributes.idioma,
              languageID: lang.attributes?.language?.data?.id
            };
          }),
          educations: item.attributes?.educations?.data?.map((profiles) => {
            return {
              id: profiles.id,
              description: profiles.attributes?.description,
              startDate: profiles.attributes?.startDate,
              endDate: profiles.attributes?.endDate,
              course: profiles.attributes?.course?.data?.attributes?.name,
              courseID: profiles.attributes?.course?.data?.id,
              faculty: profiles.attributes?.faculty?.data?.attributes?.name,
              facultyID: profiles.attributes?.faculty?.data?.id,
              country: profiles.attributes?.country?.data?.attributes?.name,
              countryID: profiles.attributes?.country?.data?.id
            };
          }),
          experiences: item.attributes?.experiences?.data?.map((profiles) => {
            return {
              id: profiles.id,
              description: profiles.attributes?.description,
              startDate: profiles.attributes?.startDate,
              endDate: profiles.attributes?.endDate,
              postRole: profiles.attributes?.postRole?.data?.attributes?.postRole,
              postRoleDescription: profiles.attributes?.postRole?.data?.attributes?.description,
              postRoleID: profiles.attributes?.postRole?.data?.id,
              expEntity: profiles.attributes?.expEntity?.data?.attributes?.name,
              expEntityID: profiles.attributes?.expEntity?.data?.id,
              country: profiles.attributes?.country?.data?.attributes?.name,
              countryID: profiles.attributes?.country?.data?.id
            };
          }),

        };
      });


      const sortedData = collaboratorData?.sort((a, b) => {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();

        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      setCollaborators(sortedData);
      setLoading(false);
    }).catch((error) => {
      console.log("Ocorreu um erro ao pegar colaborador", error);

    })
  }, [collaboratorFilter]);



  useEffect(() => {
    const newContractedCollabs = [];

    companyCollaborators.forEach((item) => {
   
      if (item?.attributes?.users_permissions_user?.data?.id == accountId && !isAdmin) {
        collaborators.forEach((collaborator) => {
          if (item?.id === collaborator?.employmentCollab?.attributes?.collaborators?.data[0]?.id) {
            // Verifica se o colaborador já está na lista de contratados
            if (!newContractedCollabs.some((collab) => collab.id === collaborator.id)) {
              newContractedCollabs.push(collaborator);
            }
          }
        });
      }
    });
    // Atualiza o estado com os dados filtrados e únicos
    setCurrentCompanyCollaborators(newContractedCollabs);
  }, [companyCollaborators, collaborators]);

  const dataToSend = isAdmin ? collaborators : currentCompanyCollaborators;
  const dataRefactored = dataToSend.slice(0, 5);

  useEffect(() => {
    setEvalId__([]);
    setItem_p([])
    setEvalitems([])
    const uniqueCollaborators = new Set();

    eval_period?.forEach((xx, idx) => {
      xx.collaborators.forEach((vx, i) => {
        setEvalitems(prev => [...prev, xx])
        setItem_p(prev => [...prev, xx.id])
        if (!uniqueCollaborators.has(xx.id)) {
          uniqueCollaborators.add(xx.id)
          setClickIndex(true)
          setEvalId__(prev => [...prev, xx])

        } else {
          setClickIndex(false)
        }
        // vx.attributes.evaluation_scores.data.map((fx)=> {
        //   if(fx.attributes.evaluation_period.data.id === xx.id){

        //   }

        // })
      });
    });



  }, [eval_period])

  function handleClickOpenView(collab_id, i, idx) {
    let data = avaliars.find(d => d.id === collab_id)
    if (eval_period.length > 0) {
      setCollabID(collab_id)
      setBtnState(false)
      setShowEvals(false)
      //setI_("1194")
      setI_(eval_period[0].id)
      setViewCollab(data)

    }
    else {
      console.log(eval_period, collab_id)
      setCollabID(collab_id)
      setViewCollab(data)
    }

  }



  const handleCloseAlert = () => {
    setOpenAlertOK(false);
  };


  function handleClickEval(collab_id, i) {


    if ((eval_period && eval_period.length === 0) | !eval_period | eval_period === undefined) {

      if (uRoleContext === 'administrator') {

        let data = avaliars.find(d => d.id === collab_id)
        const evalPeriod = adminViews?.find(ep =>
          ep.attributes.collaborators.data.some(collab => collab.id === collab_id)
        );

        if (evalPeriod.id) {

          setI_(evalPeriod.id)
          setBtnState(true)
          setShowEvals(true)
          setCollabID(collab_id)
          setViewCollab(data)


        } else {
          setOpenAlertOK(true)
          setSInfoMessage("Crie um período de avaliação para este colaborador");
          setI_('')
        }

      } else {

        setOpenAlertOK(true)
        setSInfoMessage("Crie um período de avaliação para este colaborador");
      }


    } else {

      let data = avaliars.find(d => d.id === collab_id)
      const evalPeriod = eval_period?.find(ep =>
        ep.collaborators.some(collab => collab.id === collab_id)
      );
      if (evalPeriod.id) {
        setI_(evalPeriod.id)
      } else {
        setI_('')
      }
      setBtnState(true)
      setShowEvals(true)
      setCollabID(collab_id)
      setViewCollab(data)
    }



  }

  const toggleElement = (currentIndex, idx) => {
    setClickIndex(true)
    if (clickIndex === true) {
      setOpenTarget(!openTarget)
      setDisplay(currentIndex);
    } else {
      setClickIndex(false)
      setOpenTarget(!openTarget)
      setDisplay(idx);

    }
    if (uRoleContext === 'administrator') {
      setClickIndex(true)
      setDisplay(currentIndex);

    }

  };


  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setOpenTarget(false)
        //  setClickIndex(false)
      }

    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);
  const duplicatCollabs = item_p?.filter((item, index) => item_p?.indexOf(item) === index)
  const data = [
    {
      avatar: `${REACT_APP_API_URL}`,
      name: 'Fulano de Tal',
      role: 'Front-End Developper'
    },
    {
      avatar: `${REACT_APP_API_URL}`,
      name: 'Fulano de Tal',
      role: 'Front-End Developper'
    },
  ];


  return (
    <>
      <div className="p-8 w-full">
        <div className="flex flex-col lg:flex-row items-start w-full mb-10 gap-10">
          <div className="w-[100%] lg:w-[30%] self-start">
            <AvalWelcomeCard
              title={i18n.t('dashboard.evalWelcome')}
              subtitle={'Afrikans Coders'}
              action="/collaborator/main"
            />
            <span className="mt-6 flex"></span>
            <div className="flex flex-col card">
              <div className="flex flex-col gap-2 w-full">
                <span className="w-full">
                  <StarOutline sx={{ color: '#FF9213' }} />
                </span>
                <span className="w-full font-black">Melhores avaliações</span>
              </div>
              <div className="flex flex-col space-y-2 w-full mt-8">
                {
                  bestCollaborators
                    ?.sort((a, b) => b.star - a.star) // Ordena em ordem decrescente pela pontuação 'star'
                    .map((item, index) => (
                      <CollabItem key={index} collab={item} />
                    ))
                }
              </div>
            </div>
          </div>
          <div className="w-[100%] lg:w-[70%] self-start">
            <AnalysisCardClone
              eval_periodHistory={eval_periodHistory}
              data={[]}
              companyId={companyId}
              evaluations={uRoleContext === 'administrator' && avaliars ? avaliars : []}
            />
            <span className="mt-6 flex"></span>
            <EvaluationHistoricCard eval_periodHistory={eval_periodHistory} />
          </div>

        </div>

        <div className="w-full">
          <LineMixedChart />
        </div>
      </div>

      <div className="hidden">

        <Grid container spacing={3}>

          {uRoleContext === 'administrator' ? avaliars && avaliars?.map((tx, index) =>
            <Grid item xs={isMobileMode ? 3.5 : isMediumMode ? 12 : 11} key={index}>

              <Card sx={{ height: 100 }} >
                <CardHeader
                  avatar={
                    <Avatar aria-label="collaborator">
                      <img
                        style={{ height: 50 }}
                        src={tx?.avatar ? `${REACT_APP_API_URL}${tx?.avatar}` : '/avatar.jpg'}
                        alt={tx.name} />
                    </Avatar>
                  }
                  sx={{
                    display: "flex",

                    overflow: "hidden",
                    "& .MuiCardHeader-content": {
                      overflow: "hidden"
                    }
                  }}
                  title={tx.name}
                  titleTypographyProps={{ noWrap: true }}
                  subheader={tx.postRoleDescription}
                  subheaderTypographyProps={{ noWrap: true }}
                  action={
                    <IconButton className={classes.customHoverFocus} onClick={() => toggleElement(index)}>
                      <MoreVertIcon />
                    </IconButton>
                  }

                />

              </Card>

              {openTarget && display === index ?
                <Card ref={menuRef} variant="outlined" sx={{ position: 'relative', top: -55, left: 172, width: 150 }}> <CardActions
                  disableSpacing>
                  <IconButton aria-label="view"
                    onClick={() => handleClickOpenView(tx.id, index)}>
                    <VisibilityOutlinedIcon color="primary" />
                    <Typography sx={{ paddingLeft: 1 }} id={tx.id}> {i18n.t('options.view', { lng: selectedLanguage })} </Typography>
                  </IconButton>

                </CardActions>

                  {uRoleContext !== 'administrator' && (

                    <CardActions
                      disableSpacing>
                      <IconButton aria-label="evaluate"
                        onClick={() => handleClickEval(tx.id, index)}
                      >
                        <VisibilityOutlinedIcon color="primary" />
                        <Typography sx={{ paddingLeft: 1 }} id={tx.id}> {i18n.t('options.evaluate', { lng: selectedLanguage })} </Typography>
                      </IconButton>

                    </CardActions>

                  )}


                </Card> : <></>}
            </Grid>
          ) :
            evalId__.length === 0 ? (
              <Grid item xs={12}>
                <Typography>Nenhum período de avaliação encontrado.</Typography>
              </Grid>
            ) : (

              <Grid item xs={12}>
                {evalId__.map((vs, i) =>
                  <Grid key={i}>
                    <Typography component='h3' variant='h3' sx={{ pb: 3, pt: 1, }}>Period {vs.id} </Typography>
                    <Grid sx={{ display: 'flex' }}>
                      {vs.collaborators.map((ts, index) =>
                        <Grid key={index} item xs={3} sx={{ pr: 2 }}>
                          <Card sx={{ height: 95 }} >
                            <CardHeader
                              avatar={
                                <Avatar aria-label="collaborator">
                                  <img
                                    style={{ height: 50 }}
                                    src={ts?.attributes?.image?.data?.attributes?.url ? `${REACT_APP_API_URL}${ts?.attributes?.image?.data?.attributes?.url}` : '/avatar.jpg'}
                                    alt={ts?.attributes?.name} />
                                </Avatar>
                              }
                              sx={{
                                display: "flex",
                                overflow: "hidden",
                                "& .MuiCardHeader-content": {
                                  overflow: "hidden"
                                }
                              }}
                              title={ts?.attributes?.name}
                              titleTypographyProps={{ noWrap: true }}
                              subheader={ts?.attributes.postRole.data.attributes.description}
                              subheaderTypographyProps={{ noWrap: true }}
                              action={
                                <IconButton className={classes.customHoverFocus} onClick={() => toggleElement(i, index)}>
                                  <MoreVertIcon />
                                </IconButton>
                              }

                            />

                          </Card>

                          {openTarget && display === i && clickIndex === true ?
                            <Card ref={menuRef} variant="outlined" sx={{ width: 150, position: 'absolute', mt: -6, ml: 22 }}>
                              <CardActions
                                disableSpacing>
                                <IconButton aria-label="view"
                                  onClick={() => handleClickOpenView(ts.id, i, index)}
                                >
                                  <VisibilityOutlinedIcon color="primary" />
                                  <Typography sx={{ paddingLeft: 1 }} id={ts.id}> {i18n.t('options.view', { lng: selectedLanguage })} </Typography>
                                </IconButton>

                              </CardActions>
                              <CardActions
                                disableSpacing>
                                <IconButton aria-label="evaluate"
                                  onClick={() => handleClickEval(ts.id, i, index)}
                                >
                                  <VisibilityOutlinedIcon color="primary" />
                                  <Typography sx={{ paddingLeft: 1 }} id={ts.id}> {i18n.t('options.evaluate', { lng: selectedLanguage })} </Typography>
                                </IconButton>

                              </CardActions>
                            </Card> : openTarget && display === index && clickIndex === false ?
                              <Card ref={menuRef} variant="outlined" sx={{ position: 'relative', top: -50, left: 170, width: 150 }}> <CardActions
                                disableSpacing>
                                <IconButton aria-label="view"
                                  onClick={() => handleClickOpenView(ts.id, i, index)}
                                >
                                  <VisibilityOutlinedIcon color="primary" />
                                  <Typography sx={{ paddingLeft: 1 }} id={ts.id}> {i18n.t('options.view', { lng: selectedLanguage })} </Typography>
                                </IconButton>

                              </CardActions>
                                <CardActions
                                  disableSpacing>
                                  <IconButton aria-label="evaluate"
                                    onClick={() => handleClickEval(ts.id, i, index)}
                                  >
                                    <VisibilityOutlinedIcon color="primary" />
                                    <Typography sx={{ paddingLeft: 1 }} id={ts.id}> {i18n.t('options.evaluate', { lng: selectedLanguage })} </Typography>
                                  </IconButton>

                                </CardActions>
                              </Card> : <></>}
                        </Grid>
                      )}
                    </Grid>

                  </Grid>
                )}

              </Grid>
            )
          }
          <Snackbar
            style={{ backgroundColor: 'teal', color: 'orange' }}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={openAlertOK}
            autoHideDuration={5000}
            onClose={handleCloseAlert}
          >
            <Alert severity="warning" sx={{ border: 1 }}>
              <AlertTitle sx={{ width: 500, display: 'flex', justifyContent: 'space-between' }}>
                Atenção! <CloseIcon color='secondary' onClick={handleCloseAlert} sx={{ cursor: 'pointer' }} /> </AlertTitle>
              {infoMessage}

            </Alert>
          </Snackbar>
        </Grid>
      </div>
    </>

  )
}

export default Ratings