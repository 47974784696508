import { TextSnippet, TextSnippetOutlined } from "@mui/icons-material";
import "./card.css";

const EvaluationHistoricCard = ({ eval_periodHistory }) => {


  return (
    <>
      <div className="bg-white rounded-[10px] amazing-shadow py-4 px-8 w-full min-h-[160px]">
        <span className="flex flex-col" style={{ fontWeight: 'bold' }}>
          <span className="w-full text-[#f89220]">
            <TextSnippetOutlined />
          </span>
          <span className="w-full">Últimas Avaliações</span>
        </span>

        <div className="params-analysis mt-4 flex flex-col items-center gap-3">
          {/* Cabeçalho da tabela */}
          <div className="flex py-3 items-center px-2 justify-between w-full">
            <span className="uppercase font-bold w-[40%]">Nome Colaborador</span>
            <span className="uppercase font-bold w-[40%] text-center">Data</span>
            <span className="uppercase font-bold w-[20%]"></span>
          </div>

          {/* Listagem dinâmica do histórico de avaliações */}
          {eval_periodHistory?.length > 0 ? (
             eval_periodHistory
             ?.slice(-4) // Pega os últimos 4 itens
             .reverse() // Reverte a ordem para começar pelo último da lista
             .map((evaluation, index) => (
              <div
                key={evaluation.id || index}
                className="flex items-center py-3 px-2 justify-between w-full text-sm historicItem"
              >
                {/* Nome do colaborador */}
                <span className="w-[40%]">
                  {evaluation.attributes.collaborator.data.attributes.name || "N/A"}
                </span>

                {/* Data da avaliação */}
                <span className="w-[40%] text-center">
                  {evaluation.attributes.evaluation_period.data.attributes.start || "Data não disponível"}
                </span>

                {/* Link para detalhes */}
                <a
                  href={`/ratings/${evaluation.attributes.collaborator.data.id}`}
                  className="text-[#f89220] w-[20%] ml-auto text-[0.8rem] font-semibold"
                >
                  Ver avaliação
                </a>
              </div>
            ))
          ) : (
            <div className="flex py-3 px-2 justify-center w-full text-sm">
              Nenhum histórico de avaliação disponível.
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default EvaluationHistoricCard;
