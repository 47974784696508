import React, { useEffect, useState } from "react";
import { LanguageContext } from "../../../translate/languages/LanguageContext";
import { GET_COLLABORATORS, GET_EMPLOYMENT_COLLAB } from "../../Collaborator/Collaborator_Creation_Page/query.gql";
import { useLazyQuery } from "@apollo/client";
import { Box, Button, FormControl, IconButton, InputLabel, Menu, MenuItem, Modal, Rating, Select, Snackbar } from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { CalendarMonth, Edit, EditOutlined, HowToReg, PersonPinCircle, Psychology, School, VisibilityOffOutlined, Warning } from "@mui/icons-material";
import i18n from "../../../translate/i18n";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { GET_COMPANIES } from "./query.gql";
import { GET_EVALUATION_METHODS_BY_COMPANY_ID } from "../../evaluation/mutation.gql";

const CompanyCollaborators = () => {
  const location = useLocation();
  const { comp } = location.state ?? {};

  const { companyId } = useParams();


  const [companyCollaborators, setCompanyCollaborators] = useState([]);
  const { selectedLanguage } = React.useContext(LanguageContext);
  const { REACT_APP_API_URL } = process.env;

  const [currentPage, setCurrentPage] = useState(1);
  const [isAdmin, setIsAdmin] = useState(true);
  const [openAlertOK, setOpenAlertOK] = useState(false)
  const jwt = localStorage.getItem("jwtToken");
  const userId = localStorage.getItem("userId") ?? null;
  const [getCollaborators] = useLazyQuery(GET_COLLABORATORS);
  const [getContracted] = useLazyQuery(GET_EMPLOYMENT_COLLAB);
  const [successMessage, setSuccessMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [currentUserId, setCurrentUserId] = useState(null);

  const [collaborators, setCollaborators] = useState([])
  const [colaborador, setColaborador] = useState(null);
  let navigate = useNavigate();
  const [colaboradorEdit, setColaboradorEdit] = useState(false);
  const [sortedCollaborators, setSortedCollaborators] = useState("")
  const [filteredList, setFilteredList] = useState('');
  const [collaboratorFilter, setCollaboratorFilter] = useState(null);
  const [collabContracted, setCollabContracted] = useState([]);
  const [contractedCollab, setContractedCollab] = useState([]);
  const [idCollab, setIdCollab] = useState("");

  const [getCompanys_] = useLazyQuery(GET_COMPANIES);

  const handleCloseAlert = () => {
    setOpenAlertOK(false);
  };

  const handleReturn = () => {
    setColaborador(null)
    setColaboradorEdit(false)
  };
  const listCollaborator = colaborador === null
  const returnButton = !listCollaborator && (<Button color="primary" onClick={handleReturn}>Return</Button>);

  const [getMethods, { data, error }] = useLazyQuery(GET_EVALUATION_METHODS_BY_COMPANY_ID);
  const [selectedMethod, setSelectedMethod] = useState("");
  const [allMethods, setAllMethods] = useState("");
  const handleMethodChange = (event) => {
    setSelectedMethod(event.target.value);
   
  };

  useEffect(() => {
  
    getMethods({
      variables: {
        companyId,
        pagination: { page: 1, pageSize: 10 },
      },
    }).then((data) => {
     

      setAllMethods(data.data.evaluationMethods.data)
    })

  }, [getMethods]);

  useEffect(() => {
    //setCurrentUserId
  }, [userId]);


  useEffect(() => {
    getCollaborators({
      context: {
        headers: {
          authorization: `Bearer ${jwt}`,
        },
      },
      //fetchPolicy: "no-cache",
    }).then((data) => {
   


      const collaboratorData = data?.data?.collaborators?.data?.map((item) => {
        return {
          id: item.id,
          userID: item.attributes?.users_permissions_user?.data?.id,
          name: item.attributes?.name,
          email: item.attributes?.email,
          phone: item.attributes?.phone,
          address: item.attributes?.address,
          web: item.attributes?.web,
          intro: item.attributes?.intro,
          country: item.attributes?.country?.data?.attributes?.name,
          countryID: item.attributes?.country?.data?.id,
          city: item.attributes?.city?.data?.attributes?.name,
          yearsofExperience: item.attributes?.yearsofExperience,
          speciality: item.attributes?.speciality,
          cityID: item.attributes?.city?.data?.id,
          postRole: item.attributes?.postRole?.data?.attributes?.postRole,
          postRoleID: item.attributes?.postRole?.data?.id,
          postRoleDescription: item.attributes?.postRole?.data?.attributes?.description,
          evaluations: item.attributes?.evaluations?.data,
          employmentCollab: item.attributes?.employment?.data,
          companies: item.attributes?.companies?.data/* ?.map((company) => {
              return {
                companyID: company.id,
                company: company.attributes?.name,
              };
            }) */,
          isActive: item.attributes?.isActive,
          avatar: item.attributes?.image?.data?.attributes?.url,
          avatarID: item.attributes?.image?.data?.id,
          /*   score: item.attributes?.evaluation_scores?.data?.map((score) => {
              return {
                id: score.id,
                //rateValue: score.attributes?.evaluation_answer_closeds?.data.map((score) => score.attributes.rate),
                rateLength: score.attributes?.evaluation_answer_closeds?.data.length,
                rateTotal: score.attributes?.evaluation_answer_closeds?.data.reduce((accumulator, currentScore) => accumulator + currentScore.attributes.rate, 0),
                company: score.attributes?.company?.data?.attributes?.name,
                companyID: score.attributes?.company?.data?.id
  
              };
            }), */
          profiles: item.attributes?.profiles?.data?.map((profiles) => {
            return {
              id: profiles.id,
              urlProfile: profiles.attributes?.urlProfile,
              description: profiles.attributes?.description,
              platform: profiles.attributes?.platform?.data?.attributes?.name,
              platformID: profiles.attributes?.platform?.data?.id,
              icon: profiles.attributes?.platform?.data?.attributes?.icon?.data?.attributes?.url

            };
          }),
          clientID: item.attributes?.client?.data?.id,
          client: item.attributes?.client?.data?.attributes?.companies?.data?.map((client) => {
            /* const matchingScores = item.attributes?.evaluation_scores?.data?.filter((score) => score.attributes?.company?.data?.id === client.id);
        const rateValue = matchingScores.flatMap((matchingScore) =>
            matchingScore?.attributes?.evaluation_answer_closeds?.data.map((score) => score.attributes.rate)
          );
          const rateLength = rateValue.length;
          const rateTotal = rateValue.reduce((accumulator, currentScore) => accumulator + currentScore, 0);
*/
            return {
              id: client.id,
              name: client.attributes?.name,
              image: client.attributes?.image?.data?.attributes?.url,
              /*   rateTotal: rateTotal,
                rateLength: rateLength,
                rateValue: rateValue, */
            };
          }),


          portfolios: item.attributes?.portfolios?.data?.map((portfolios) => {
            return {
              id: portfolios.id,
              name: portfolios.attributes?.name,
              description: portfolios.attributes?.description,
              image: portfolios.attributes?.image?.data?.attributes?.url,
              imageID: portfolios.attributes?.image?.data?.id
            };
          }),
          awards: item.attributes?.awards?.data?.map((profiles) => {
            return {
              id: profiles.id,
              name: profiles.attributes?.name,
              description: profiles.attributes?.description
            };
          }),
          skills: item.attributes?.skills?.data?.map((profiles) => {
            return {
              id: profiles.id,
              percentual: profiles.attributes?.percentual,
              name: profiles.attributes?.itTool?.data?.attributes?.name,
              itToolID: profiles.attributes?.itTool?.data?.id
            };
          }),
          expertise: item.attributes?.expertise?.data?.map((profiles) => {
            return {
              id: profiles.id,
              description: profiles.attributes?.description,
              expertise: profiles.attributes?.expertise?.data?.attributes?.name,
              expertiseID: profiles.attributes?.expertise?.data?.id
            };
          }),
          languages: item.attributes?.language_collaborators?.data?.map((lang) => {
            return {

              name: lang.attributes?.language.data.attributes.idioma,
              languageID: lang.attributes?.language?.data?.id
            };
          }),
          educations: item.attributes?.educations?.data?.map((profiles) => {
            return {
              id: profiles.id,
              description: profiles.attributes?.description,
              startDate: profiles.attributes?.startDate,
              endDate: profiles.attributes?.endDate,
              course: profiles.attributes?.course?.data?.attributes?.name,
              courseID: profiles.attributes?.course?.data?.id,
              faculty: profiles.attributes?.faculty?.data?.attributes?.name,
              facultyID: profiles.attributes?.faculty?.data?.id,
              country: profiles.attributes?.country?.data?.attributes?.name,
              countryID: profiles.attributes?.country?.data?.id
            };
          }),
          experiences: item.attributes?.experiences?.data?.map((profiles) => {
            return {
              id: profiles.id,
              description: profiles.attributes?.description,
              startDate: profiles.attributes?.startDate,
              endDate: profiles.attributes?.endDate,
              postRole: profiles.attributes?.postRole?.data?.attributes?.postRole,
              postRoleDescription: profiles.attributes?.postRole?.data?.attributes?.description,
              postRoleID: profiles.attributes?.postRole?.data?.id,
              expEntity: profiles.attributes?.expEntity?.data?.attributes?.name,
              expEntityID: profiles.attributes?.expEntity?.data?.id,
              country: profiles.attributes?.country?.data?.attributes?.name,
              countryID: profiles.attributes?.country?.data?.id
            };
          }),

        };
      });


      const sortedData = collaboratorData?.sort((a, b) => {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();

        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      setCollaborators(sortedData);
      setLoading(false);
    }).catch((error) => {
      console.log("Ocorreu um erro ao pegar colaborador", error);

    })
  }, [collaboratorFilter]);

  useEffect(() => {
    if (comp) {
      setCompanyCollaborators(comp?.attributes?.collaborators?.data ?? []);
    } else {
      getCompanys_({
        context: {
          headers: {
            authorization: `Bearer ${jwt}`,
          },
        },
      }).then((data) => {
        const companyCollabs = data?.data?.companies?.data?.filter((item) => item?.id === companyId)
        setCompanyCollaborators(companyCollabs[0]?.attributes?.collaborators?.data ?? []);
      });
    }

  }, [comp]);


  /* useEffect(() => {
   getContracted({
     context: {
       headers: {
         authorization: `Bearer ${jwt}`,
       },
     },
     fetchPolicy: "no-cache",
   }).then((result) => {
     console.log("Resultado bruto da query:", result);
     setCollabContracted(result?.data?.employments.data);
     const contract = result?.data?.employments?.data?.map(({ id, attributes }) => {
        const companyName = attributes?.companies?.data?.[0]?.attributes?.name || "Unknown Company";
 
        return {
          id,
          company: companyName,
        };
      }); 

     //console.log("CONTRATOS: ", contract);
   });

   const role = localStorage.getItem('userRole') ?? '';
   if (role == 'administrator') {
     setIsAdmin(!isAdmin);
   }


 }, []);*/


  useEffect(() => {
    getContracted({
      variables: {
        filters: {
          companies: {
            id: {
              eq: companyId, // Filtro pelo ID da empresa
            },
          },
        },
        pagination: {
          limit: 10, // Você pode ajustar o limite, se necessário
        },
        sort: ["startDate:desc"], // Ordenação, opcional
      },
      context: {
        headers: {
          authorization: `Bearer ${jwt}`,
        },
      },
      fetchPolicy: "no-cache",
    })
      .then((result) => {
        console.log("Resultado bruto da query:", result);
        setCollabContracted(result?.data?.employments?.data || []);
      })
      .catch((err) => {
        console.error("Erro ao buscar os dados:", err);
      });
  }, [companyId]);

  useEffect(() => {
    const newContractedCollabs = [];
    console.log("companyCollaborators", companyCollaborators);
    companyCollaborators.forEach((item) => {
   
      collaborators.forEach((collaborator) => {
        if (item?.id === collaborator?.employmentCollab?.attributes?.collaborators?.data[0]?.id) {
          // Verifica se o colaborador já está na lista de contratados
          if (!newContractedCollabs.some((collab) => collab.id === collaborator.id)) {
            newContractedCollabs.push(collaborator);
          }
        }
      });
    });

    // Atualiza o estado com os dados filtrados e únicos
    setContractedCollab(newContractedCollabs);
    console.log("lista decontratados", newContractedCollabs);
  }, [companyCollaborators, collaborators]);



  if (!collabContracted || collabContracted.length == 0) {
    return (
      <>
        <div className="flex items center w-full border-t border-b py-5 mb-10">
          <div className="flex flex-col px-6">
            <span style={{ fontSize: '1rem' }} className="font-onest self-start">
              {/* Resultados para */}
            </span>
            <span style={{ fontSize: '1.45rem', textTransform: 'capitalize' }} className="font-bold font-onest self-start">{i18n.t('tittles.company_my_cols', { lng: selectedLanguage })}</span>
          </div>
          <a
            href="/companies"
            className='border border-[#F89220] text-[#F89220] py-3.5 px-6 hover:opacity-60 rounded-[0.25rem] ml-auto mr-2'>
            Voltar
          </a>
        </div>
        <div className="px-6">
          <div className="card flex flex-col w-full">
            <span className="flex items-center w-full gap-2 mr-auto">
              <span className="w-[40px] h-[40px] aspect-square flex items-center justify-center bg-[#f89220] rounded-full">
                <Warning sx={{ color: '#fff' }} />
              </span>
              <div className="flex flex-col w-full">
                <p className="mr-auto">Nenhum colaborador associado a empresa.</p>
                <a
                  href="/collaborator/main"
                  className='text-[#F89220] hover:opacity-60 rounded-[0.25rem] mr-auto'>
                  Ver a lista dos colaboradores
                </a>
              </div>
            </span>
          </div>
        </div>
      </>
    );
  }

  const itemsPerPage = 6;

  const lastIndex = currentPage * itemsPerPage;
  const firstIndex = lastIndex - itemsPerPage;
  // const myArr = [];
  // companyCollaborators.filter((item, index) => {
  //   for (var indice = 0; indice < collaborators.length; indice++) {
  //     if (item?.id == collaborators[indice]?.employmentCollab?.attributes?.collaborators?.data[0]?.id) {
  //       console.log("SHHHHHHHHHHHHA22", collaborators[indice]);
  //       myArr.push(collaborators[indice]);
  //     }
  //   }
  // });
  // setContractedCollab(myArr);

  const collabItems = contractedCollab; //isAdmin ? collaborators : collaborators?.filter((item, index) => item.employmentCollab.id != null);


  const currentItems = collabItems?.slice(firstIndex, lastIndex);

  const totalPages = Math.ceil(collabItems?.length / itemsPerPage);

  // Handle the page change event
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const goToNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const calculateExperienceYears = (experiences) => {



    let totalYears = 0;

    experiences?.forEach((experience) => {
      const startDate = new Date(experience.startDate);
      const endDate = new Date(experience.endDate);
      const years = endDate.getFullYear() - startDate.getFullYear();

      // Ajusta se o mês e dia do endDate ainda não ocorreram este ano
      if (
        endDate.getMonth() < startDate.getMonth() ||
        (endDate.getMonth() === startDate.getMonth() && endDate.getDate() < startDate.getDate())
      ) {
        totalYears -= 1;
      }

      totalYears += years;
    });


    return totalYears;
  };

  const handleSave = () => {
    console.log("Guardado com método:", selectedMethod);

    navigate(`/ratings/${idCollab}/${selectedMethod}`);
    handleCloseAlert();
  };


  function LongMenu({ collaboratorID, collaboratorData, contractText, collaboratorStep, rowId, row }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const booleanStatus = collaboratorData.isActive;

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };



    const handleClose = () => {
      setAnchorEl(null);
    };


    const openModal = (id) => {
      setIdCollab(id)
      setOpenAlertOK(true)

    };

    return (
      <>
        <IconButton aria-label="more" onClick={handleClick}>
          <MoreVertIcon />
        </IconButton>
        <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
          <MenuItem onClick={() => openModal(rowId)}>
            <Edit color='primary' style={{ marginRight: '5px' }} /> {i18n.t('options.evaluate', { lng: selectedLanguage })}
          </MenuItem>


          {/* <MenuItem onClick={() => handleClickAssociate(contractText)}>
            <CloudCircleOutlined color='primary' style={{ marginRight: '5px' }} />
            {contractText}
          </MenuItem> */}
        </Menu>
      </>
    );
  }

  console.log("currentItems", currentItems);


  return (
    <>
      <div className="flex items center w-full border-t border-b py-5 mb-10">
        <div className="flex flex-col px-6">
          <span style={{ fontSize: '1rem' }} className="font-onest self-start">
            {/* Resultados para */}
          </span>
          <span style={{ fontSize: '1.45rem', textTransform: 'capitalize' }} className="font-bold font-onest self-start">{i18n.t('tittles.company_my_cols', { lng: selectedLanguage })}</span>
        </div>
        <a
          href="/companies"
          className='border border-[#F89220] text-[#F89220] py-3.5 px-6 hover:opacity-60 rounded-[0.25rem] ml-auto mr-2'>
          Voltar
        </a>
      </div>

      <div className="w-full px-6 -mt-6 pb-6 relative grid md:grid-cols-2 lg:grid-cols-4 gap-6">
        {
          currentItems?.map((row, index) => (
            <div
              key={index}
              className="bg-white rounded-[12px] border border-gray-200 mt-6 shadow-md relative py-6 px-6"
            >
              <div className="absolute top-4 right-4">
                <LongMenu
                  collaboratorID={row.id}
                  collaboratorData={row}
                  rowId={row.id}
                  row={row}
                />
              </div>
              <div className="flex">
                <div className='flex self-start items-center jusitfy-center'>
                  {
                    row?.avatar ? (
                      <img
                        src={`${REACT_APP_API_URL}${row?.avatar}`}
                        className='w-[50px] h-[50px] aspect-square rounded-full border object-top'
                      />
                    ) : (

                      <span className="flex items-center justify-center w-[50px] h-[50px] aspect-square rounded-full bg-[#EA1967] text-white text-xl lg:text-2xl">{row?.name?.[0] ?? ''}</span>
                    )
                  }
                </div>
                <div className="flex flex-col ml-2">
                  <h4 className="font-bold self-start" style={{ fontSize: '0.85rem' }}>
                    {row?.name?.split(" ")[0]} {row?.name && row?.name?.split(" ").pop()}
                  </h4>
                  <small style={{ fontSize: '0.85rem' }} className="text-[rgba(0,0,0,0.65)] self-start -mt-1">{row?.speciality ?? 'N/A'}</small>
                  <div className="flex self-start items-center mb-2" style={{ fontSize: 16 }}>
                    {
                      row?.evaluations?.length > 0 ? (
                        <Rating
                          value={
                            row.evaluations.reduce((sum, evaluation) => {
                              const star = evaluation?.attributes?.star || 0; // Garante que star seja acessado corretamente
                              return sum + star / 20; // Converte star para escala de 0-5
                            }, 0) / row.evaluations.length // Divide pelo número de avaliações
                          }
                          precision={0.5}
                          readOnly

                        />
                      ) : (
                        <Rating
                          value={0}
                          precision={0.5}
                          readOnly

                        />
                      )
                    }
                  </div>
                </div>
              </div>

              <div className="flex flex-col">
                <div className="flex flex-col w-full">
                  <span className='font-bold flex w-full text-[#F89220] items-center'>
                    <Psychology sx={{ fontSize: 18 }} /> {" "}
                    {i18n.t('collaborator.skills', { lng: selectedLanguage })}
                  </span>
                  <div className="flex items-center gap-2 w-full">
                    {

                      row?.skills?.length > 0 ? row?.skills?.map((skill, index) => (
                        index <= 2 ? (
                          <small key={index}>
                            {skill?.name}({skill?.percentual}%)
                          </small>
                        ) : null
                      )) : (
                        <small className='text-gray-400'>Nenhum...</small>
                      )
                    }
                  </div>
                </div>
                <div className="flex flex-col w-full mt-4">
                  <span className='font-bold block w-full text-[#F89220]'>
                    <School sx={{ fontSize: 18 }} /> {" "}
                    {i18n.t('collaborator.education', { lng: selectedLanguage })}
                  </span>
                  <div className="flex items-center gap-2 w-full">
                    {
                      row?.educations?.length > 0 ? (
                        <div className='flex flex-col w-full'>
                          <small className='w-full overflow-hidden text-ellipsis line-clamp-2' style={{ fontWeight: 'bold' }}>{row?.educations[0]?.course}</small>
                          <small className='w-full text-gray-400 whitespace-nowrap text-ellipsis line-clamp-1 overflow-hidden'>{row?.educations[0]?.faculty}</small>
                        </div>
                      ) : (
                        <small className='text-gray-400'>Nenhum...</small>
                      )
                    }
                  </div>
                </div>
              </div>

              <p className='text-sm pt-2 opacity-75 hidden font-medium mt-2 border-t overflow-hidden text-ellipsis line-clamp-2 h-[50px]'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Mollitia distinctio accusamus hic commodi ut assumenda?</p>
              <span></span>
              <div className="flex flex-col w-full border-t mt-2 pt-1.5">
                <small className='w-full flex items-center gap-1 uppercase text-[#F89220]' style={{ fontWeight: 'bold' }}>
                  <CalendarMonth sx={{ fontSize: 17 }} /> {i18n.t('collaborator.experienceYears', { lng: selectedLanguage })}: {" "}
                  <span className='font-bold text-black'>
                    {calculateExperienceYears(row?.experiences ?? [])}
                  </span>
                </small>
                <small className='w-full flex items-center gap-2 text-[#F89220]'>
                  <span className="flex items-center gap-1 uppercase" style={{ fontWeight: 'bold' }}>
                    <HowToReg sx={{ fontSize: 19 }} /> {i18n.t('collaborator.role', { lng: selectedLanguage })}: {" "}
                  </span>
                  <span className='font-bold text-black whitespace-nowrap text-ellipsis line-clamp-1 overflow-hidden'>
                    {row?.postRoleDescription ?? 'N/A'}
                  </span>
                </small>
              </div>
            </div>
          ))
        }
      </div>
      <Modal open={openAlertOK} onClose={handleCloseAlert}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "white",
            borderRadius: "8px",
            boxShadow: 24,
            p: 4,
            minWidth: "400px",
          }}
        >
          <h2 style={{ textAlign: "center", marginBottom: "16px", color: "orange" }}>
            Escolha o Método
          </h2>
          <FormControl fullWidth style={{ marginBottom: "16px" }}>
            <Select
              placeholder="Selecione o Método"
              
              value={selectedMethod}
              onChange={handleMethodChange}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 300, // Limita a altura do menu, se necessário
                  },
                },
              }}
            >
              {allMethods.map((method) => (
                <MenuItem key={method.id} value={method.id}>
                  {method.attributes.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "16px",
            }}
          >
            <Button
              variant="outlined"
              color="error"
              onClick={handleCloseAlert}
              style={{ width: "45%" }}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSave}
              style={{ width: "45%" }}
              disabled={!selectedMethod} // Desabilita o botão se nenhum método for selecionado
            >
              Guardar
            </Button>
          </div>
        </Box>
      </Modal>

    </>
  );
};




export default CompanyCollaborators;