import { Timeline } from "@mui/icons-material";
import AnalysisCardItem from "./AnalysisCardItem";
import { GET_COMPANIES } from "../../CompanyPage/Company_Creation_Page/query.gql";
import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { GET_COLLABORATORS, GET_EMPLOYMENT_COLLAB } from "../../Collaborator/Collaborator_Creation_Page/query.gql";

const AnalysisCardClone = ({ props: data, evaluations, eval_periodHistory, companyId }) => {
  const [companies, setCompanies] = useState([]);
  const jwt = localStorage.getItem("jwtToken");
  const [collaborators, setCollaborators] = useState([]);
  const [hiredCollaborators, setHiredCollaborators] = useState(0);
  const [getCollaborators] = useLazyQuery(GET_COLLABORATORS);
  const [getComp] = useLazyQuery(GET_COMPANIES);
  const [isAdmin, setIsAdmin] = useState(false);
  const [currentCompanyId, setCurrentCompanyId] = useState(1238);
  const loggedId = localStorage.getItem('userId');
  const userRole = localStorage.getItem('userRole') ?? null;
  const [collabContracted, setCollabContracted] = useState([]);
   const [getContracted] = useLazyQuery(GET_EMPLOYMENT_COLLAB);

  useEffect(() => {
    if (userRole) {
      setIsAdmin(userRole == 'administrator');
    }
  }, [userRole]);

  useEffect(() => {
    getComp({
      context: {
        headers: {
          authorization: `Bearer ${jwt}`,
        },
      },
      fetchPolicy: 'network-only',
    }).then((response) => {
      setCompanies(response?.data?.companies?.data);
   
        const companyCollabs = response?.data?.companies?.data?.filter((item) => item?.id === companyId)
        setCollabContracted(companyCollabs[0]?.attributes?.collaborators?.data ?? []);
     
    })
  }, [companyId]);

  useEffect(() => {
    getCollaborators({
      context: {
        headers: {
          authorization: `Bearer ${jwt}`,
        },
      },
      //fetchPolicy: "no-cache",
    }).then((data) => {

      const collaboratorsData = data?.data?.collaborators?.data || []; // Garante que é um array
      setCollaborators(collaboratorsData);

      if (collaboratorsData.length > 0) {
        const companies = collaboratorsData.flatMap((item) => {
          return item?.attributes?.companies?.data?.filter((comp) => {
            const iii = comp?.attributes?.users_permissions_user?.data?.id;
            if (iii && iii === loggedId) {
              setCurrentCompanyId(comp?.id);
              return true;
            }
            return false;
          });
        });
        setHiredCollaborators(companies.length);
      } else {
        setHiredCollaborators(0);
      }
    });
  }, []);

 /*  useEffect(() => {
   
    
    getContracted({
      variables: {
        filters: {
          companies: {
            id: {
              eq: currentCompanyId, // Filtro pelo ID da empresa
            },
          },
        },
        pagination: {
          limit: 10, // Você pode ajustar o limite, se necessário
        },
        sort: ["startDate:desc"], // Ordenação, opcional
      },
      context: {
        headers: {
          authorization: `Bearer ${jwt}`,
        },
      },
      fetchPolicy: "no-cache",
    })
      .then((result) => {
     
        setCollabContracted(result?.data?.employments?.data || []);
      })
      .catch((err) => {
        console.error("Erro ao buscar os dados:", err);
      });
  }, [currentCompanyId]); */


  return (
    <>
      <div className="bg-white rounded-[10px] amazing-shadow pt-4 pb-6 px-8 w-full">
        <span style={{ fontWeight: 'bold' }} className="flex flex-col w-full">
          <span className="w-full">
            <Timeline sx={{ color: '#FF9213' }} />
          </span>
          <span className="w-full">Análises</span>
        </span>
        <div className="params-analysis mt-4 grid lg:grid-cols-3 items-center gap-3">

          <AnalysisCardItem title="Colaboradores" count={collaborators.length} link="/collaborator/main" />
          <AnalysisCardItem
            title="Total Avaliações"
            count={eval_periodHistory?.length ?? 0}
            link="/evaluation/all"
            state={evaluations}
          />
          {
            isAdmin ? (
              <AnalysisCardItem
                title="Empresas"
                count={companies.length}
                link="/companies"
              />
            ) : (
              <AnalysisCardItem
                title="Contratados"
                count={collabContracted?.length ?? 0}
                link={`/company/${currentCompanyId}/collaborators`}
              />
            )
          }
        </div>
      </div>
    </>
  );
};

export default AnalysisCardClone;