import React, { useContext, useEffect, useState } from "react";
import { Card, Grid, Input, Select } from "@mui/material";
import Pathway from "../../components/TopBar/pathway";
import { Add, Close, PlusOneOutlined } from "@mui/icons-material";
import { toast } from "react-toastify";
import "./EvaluationParam.css";
import i18n from "../../translate/i18n";
import { LanguageContext } from "../../translate/languages/LanguageContext";
import { CREATE_EVALUATION_CRITERY, CREATE_EVALUATION_CRITERY_SCORES, CREATE_EVALUATION_METHOD } from "./mutation.gql";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_COMPANIES } from "../CompanyPage/Company_Creation_Page/query.gql";

const EvaluationParam = () => {
  const { selectedLanguage } = useContext(LanguageContext);
  const [add_critery_method] = useMutation(CREATE_EVALUATION_METHOD);
  const [add_critery] = useMutation(CREATE_EVALUATION_CRITERY);
  const jwt = localStorage.getItem("jwtToken");
  const [criterios, setCriterios] = useState([]);
  const [evaluationType, setEvaluationType] = useState([]);

  const [descricao, setDescricao] = useState("");
  const [criterioAval, setCriterioAval] = useState("");
  const [percent, setPercent] = useState(0);
  const [progressValue, setProgressValue] = useState(0);
 const [getComp] = useLazyQuery(GET_COMPANIES);
  const [loadingButton, setLoadingButton] = useState(false);

  const [showAddMethod, setShowAddMethod] = useState(true); // Controla a exibição da seção de "Add Method Name"
  const [companies, setCompanies] = useState([]);


  useEffect(() => {
    getComp({
      context: {
        headers: {
          authorization: `Bearer ${jwt}`,
        },
      },
      fetchPolicy: 'network-only',
    }).then((response) => {
     
      setCompanies(response?.data?.companies?.data);
    })
  }, []);

  const salvarCriterios = async () => {
    if (criterios.length === 0) {
      toast.warning("Sem Critério de avaliação!");
      return;
    } else {
      const savedScoreIds = [];
      setLoadingButton(true)
   
      
      for (const item of criterios) {
        const response = await add_critery({
          context: {
            headers: {
              authorization: `Bearer ${jwt}`,
            },
          },
          variables: {
            data:{
              name: item.criterio,
              percentage: item.percentagem,
            }
           
          },
        });
        savedScoreIds.push(response.data.createCriteria.data.id);
      }

      add_critery_method({
        context: {
          headers: {
            authorization: `Bearer ${jwt}`,
          },
        },

        variables: {
          data:{
            name: evaluationType[0].tipo,
            company: companies[0].id || "",
            criteria: savedScoreIds,
          }
         

        },
      }).then((data) => {
   

        toast.success("Critérios salvos com sucesso!");
        setDescricao("")
        setCriterioAval("")
        setPercent("")
        setProgressValue("")
        setShowAddMethod(true)
        setCriterios([])
        setEvaluationType([])
        setLoadingButton(false)
      }).catch((err) => {
        toast.error("Ocorreu um erro. Tente novamente!");
        console.log("error", err);
        setLoadingButton(false)

      })
    }


  };
  

  const adicionarCriterio = () => {
    const restante = 100 - progressValue;

    if (criterioAval.length === 0) {
      toast.warning("Insira o critério de avaliação!");
      return;
    }

    if (percent <= 0) {
      toast.warning("A percentagem deve ser maior que 0!");
      return;
    }

    if (percent > restante) {
      toast.warning(`Só restam ${restante}% disponíveis!`);
      return;
    }

    setCriterios((prevItems) => [
      ...prevItems,
      { criterio: criterioAval, percentagem: percent },
    ]);
    setProgressValue(progressValue + percent);
    setPercent(0);
    setCriterioAval("");
  };

  const adicionarTipoAval = () => {
    if (descricao.length === 0) {
      toast.warning("Insira um tipo!");
      return;
    }

    setEvaluationType((prevItems) => [...prevItems, { tipo: descricao }]);
    setDescricao("");
    setShowAddMethod(false); // Esconde a seção de "Add Method Name"
  };

  return (
    <div className="px-6">
      <Grid container maxWidth="lg" style={{ margin: "0 auto" }}>
        <div className="shadow-sm w-full p-10 bg-white mt-10">
          {showAddMethod && (
            <div>
              <span className="font-bold pb-5">
                {i18n.t("evaluationParam.newMethod", {
                  lng: selectedLanguage,
                })}
              </span>
              <div className="flex items-center w-full -mt-3">
                <div className="flex flex-col w-[80%] items-start">
                  <input
                    id="descricao"
                    onChange={(e) => setDescricao(e.target.value)}
                    className="outline-none w-full border-[1.55px] rounded-md p-[1rem] mt-2"
                    placeholder={i18n.t("evaluationParam.write", {
                      lng: selectedLanguage,
                    })}
                    value={descricao}
                  />
                </div>
                <div className="flex flex-col w-[15%] ml-auto">
                  <button
                    type="button"
                    onClick={adicionarTipoAval}
                    className="w-full flex items-center p-4 rounded-md justify-center bg-[#F89220] text-white"
                  >
                    <span>{i18n.t("button.add", { lng: selectedLanguage })}</span>
                  </button>
                </div>
              </div>
            </div>
          )}

          {evaluationType.length > 0 && (
            <>
              <span className="mt-4 border-t border-b font-bold text-[#F89220] py-2.5 block w-full">
                {i18n.t("evaluationParam.methodsAdded", {
                  lng: selectedLanguage,
                })}
              </span>

              <div className="flex flex-wrap gap-2 pt-4">
                {evaluationType.map((evalType, index) => (
                  <div
                    key={index}
                    className="flex items-center bg-slate-200 py-2 px-3 rounded-md"
                  >
                    <small>{evalType.tipo}</small>

                  </div>
                ))}
              </div>
            </>
          )}

          {evaluationType.length > 0 && (
            <div>
              <div className="flex items-center flex-col lg:flex-row gap-4 mt-4">
                <div className="flex-1">
                  <label className="text-gray-400 text-sm">
                    {i18n.t("evaluationParam.evalCriterion", {
                      lng: selectedLanguage,
                    })}
                  </label>
                  <input
                    type="text"
                    onChange={(e) => setCriterioAval(e.target.value)}
                    className="border w-full rounded-md p-4"
                    placeholder={i18n.t("evaluationParam.defineCriterion", {
                      lng: selectedLanguage,
                    })}
                    value={criterioAval}
                  />
                </div>
                <div className="flex-1">
                  <label className="text-gray-400 text-sm">
                    {i18n.t("evaluationParam.percentage", {
                      lng: selectedLanguage,
                    })}
                  </label>
                  <input
                    type="number"
                    onChange={(e) => setPercent(parseInt(e.target.value, 10))}
                    className="border w-full rounded-md p-4"
                    value={percent}
                  />
                </div>
                <button
                  type="button"
                  onClick={adicionarCriterio}
                  className="bg-[#F89220] text-white p-4 rounded-md"
                >
                  {i18n.t("button.add", { lng: selectedLanguage })}
                </button>
              </div>

              <div className="border-t pt-4 mt-4">
                <div className="progress-bar">
                  <span
                    className="progress"
                    style={{ width: `${progressValue}%` }}
                  ></span>
                </div>
                <span className="font-bold">{progressValue}%</span>
              </div>

              <div className="mt-4">
                {criterios.map((item, index) => (
                  <div key={index} className="flex items-center gap-4">
                    <span>{item.criterio}</span>
                    <span>{item.percentagem}%</span>
                    <button
                      onClick={() => {
                        setCriterios((prevItems) =>
                          prevItems.filter((_, i) => i !== index)
                        );
                        setProgressValue(progressValue - item.percentagem);
                      }}
                    >
                      <Close />
                    </button>
                  </div>
                ))}
              </div>


              <div className=" flex items-end justify-end mt-10 gap-2">
                <button
                  type="button"
                  onClick={salvarCriterios}
                  className={`w-100 mt-4 p-4 rounded-md text-white flex items-center justify-center ${loadingButton
                      ? "bg-gray-400 cursor-not-allowed"
                      : progressValue === 100 && criterios.length > 0
                        ? "bg-[#F89220]"
                        : "bg-gray-400 cursor-not-allowed"
                    }`}
                  disabled={loadingButton || progressValue !== 100 || criterios.length === 0}
                >
                  {loadingButton ? (
                    <span className="flex items-center">
                      <svg
                        className="animate-spin h-5 w-5 mr-3 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8v4a4 4 0 00-4 4H4z"
                        ></path>
                      </svg>
                      Loading...
                    </span>
                  ) : (
                    i18n.t("button.save", { lng: selectedLanguage })
                  )}
                </button>


              </div>
            </div>
          )}
        </div>
      </Grid>
    </div>
  );
};

export default EvaluationParam;
