import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Grid, Rating, StepIcon, TextField, Typography, useMediaQuery } from '@mui/material';
import { LanguageContext } from "../../../translate/languages/LanguageContext";
import { useContext } from "react";
import i18n from "../../../translate/i18n";
import { theme } from '../../../components/Theme';

// const CustomStepIcon = ({ active, completed }) => {
//   let iconImage;
//   let iconSize = active ? '1.5rem' : '0.8rem';
//   let marginLeft = active ? '0.05rem' : '0.35rem';

//   if (active) {
//     iconImage = '/africanStepperOrange.svg';
//   }
//   else if (completed) {
//     iconImage = '/africanStepperOrange.svg';
//   } else {
//     iconImage = '/africanStepperGray.svg';
//   }

//   return (
//     <div style={{ position: 'relative' }}>
//       <img src={iconImage} alt="Step Icon" style={{ width: iconSize, height: iconSize, marginLeft: marginLeft }} />
//       <StepIcon icon={null} /> {/* Hide the default icon */}
//     </div>
//   );
// };


export default function VerticalLinearStepper({
  step,
  methods, // Lista de métodos de avaliação (data)
  handleStep, // Função para mudar de step
  handleScoreChange, // Função para registrar a pontuação
  setEvaluations,
  evaluations
}) {
  const [activeStep, setActiveStep] = React.useState(step);
  const [scores, setScores] = React.useState({}); // Armazena pontuações de cada critério


const handleScoreUpdate = (criterionId, score) => {
  setScores((prevScores) => {
    const updatedScores = {
      ...prevScores,
      [criterionId]: score,
    };
    updateEvaluations(updatedScores); // Atualiza a estrutura de evaluations
    return updatedScores;
  });
  handleScoreChange(criterionId, score); // Callback para atualizar globalmente
};

  // Função para atualizar a estrutura de evaluations
  const updateEvaluations = (updatedScores) => {
    const newEvaluations ={
      id: methods?.id,
      name: methods?.attributes?.name,
      criteria: methods.attributes?.criteria?.data?.map((cri) => {
        const score = updatedScores[cri.id] || 0; // Pontuação dada pelo usuário
        return {
          id: cri.id,
          name: cri.attributes.name,
          percentage: cri.attributes.percentage,
          score: score, // Atualiza com a pontuação de updatedScores
        };
      }),
    };

    // Atualiza o estado com a nova estrutura de avaliações
    setEvaluations(newEvaluations);
  };

  React.useEffect(() => {
    setActiveStep(step);
  }, [step]);

  return (
    <Box sx={{ maxWidth: 600, margin: "auto" }}>
       <Stepper activeStep={activeStep} orientation="vertical">
     
          <Step key={methods?.id} >
            {/* Nome do Método */}
            <StepLabel>
              <Typography variant="h6" fontWeight="bold">
                {methods?.attributes?.name}
              </Typography>
            </StepLabel>

            {/* Critérios do Método */}
            <Box sx={{ pl: 3, mt: 2 }}>
              {methods?.attributes?.criteria?.data?.map((cri) => (
                <Box
                  key={cri.id}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    mb: 3,
                    border: "1px solid #ccc",
                    borderRadius: "8px",
                    padding: "16px",
                    backgroundColor: "#f9f9f9",
                  }}
                >
                  {/* Nome do Critério */}
                  <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
                    {cri.attributes.name}
                  </Typography>

                  {/* Percentagem */}
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{ mb: 2 }}
                  >
                    Peso: {cri.attributes.percentage}%
                  </Typography>

                  {/* Componente de Estrelas */}
                  <Rating
                    size="large"
                    name={`rating-${cri.id}`}
                    precision={0.5}
                    value={scores[cri.id] || 0}
                    onChange={(event, newValue) =>
                      handleScoreUpdate(cri.id, newValue)
                    }
                  />

                  {/* Campo numérico para pontuação (opcional) */}
                  <TextField
                    type="number"
                    size="medium"
                    variant="outlined"
                    sx={{ mt: 2, width: "150px" }}
                    value={scores[cri.id] || ""}
                    onChange={(e) =>
                      handleScoreUpdate(cri.id, Number(e.target.value))
                    }
                    inputProps={{ min: 0, max: 100 }}
                  />
                </Box>
              ))}
            </Box>
          </Step>
        
      </Stepper>
    </Box>
  )
}