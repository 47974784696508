// CollaboratorToCompany.jsx
import React, { useEffect, useState, useContext } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  CircularProgress,
  Autocomplete,
  Typography,
  Box,
  TextField,
  Grid
} from '@mui/material';
import Stack from '@mui/material/Stack';

import { useMutation, useQuery } from '@apollo/client';
import { CREATE_EMPLOYMENT_COLLAB, CREATE_AVAL_PERIOD, ASSOC_CTOC, TESTE_2, UPDATE_COMPANY_C, DELETE_EMPLOYMENT_BY_COLLABORATOR, DELETE_NOTIFICATION_MUTATION, UPDATE_EMPLOYMENT_TO_NULL } from './mutation.gql';
import { GET_COLLABORATORS, GET_COMP_GET } from './query.gql';
import { GET_COMPANIES, GET_USER_DATA } from "../../CompanyPage/Company_Creation_Page/query.gql";
import { toast } from 'react-toastify';
import jwtDecode from "jwt-decode";
import { useLazyQuery } from "@apollo/client";
import { GET_COLLABORATOR_BY_ID_TO_COMPANY } from '../Collaborator_View_Page/query.gql';

const CollaboratorToCompany = ({ open, handleClose, idCollaborador, action, idCompany = null, notificationId = null, nameCollaborador = null, collabRole = null }) => {
  const jwt = localStorage.getItem("jwtToken");
  const { id: employeerID } = jwtDecode(jwt);

  const [isAlreadyAssociated, setIsAlreadyAssociated] = useState(false);
  const [loadingCheck, setLoadingCheck] = useState(true);
  const [currentCompanyId, setCurrentCompanyId] = useState("");
  const [currentOption, setCurrentOption] = useState(-1);
  const [salary, setSalary] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  const [updateEmploymentToNull] = useMutation(UPDATE_EMPLOYMENT_TO_NULL);



  useEffect(() => {
    if (open) {
      if (action == 0) {
        setIsAlreadyAssociated(true);
      }
      setLoadingCheck(false);
    }
    // (alert)(idCollaborador);
    // alert(employeerID);
  }, [open]);

  useEffect(() => {
    if (open) {
      console.log(`Associando colaborador com ID: ${idCollaborador}`);
    }
    // alert(idCollaborador)    
    const role = localStorage.getItem('userRole') ?? '';
    if (role == 'administrator') {
      setIsAdmin(!isAdmin);
    }
  }, [idCollaborador, open]);

  const [get_companies] = useLazyQuery(GET_COMP_GET);
  const [get_collaborator] = useLazyQuery(GET_COLLABORATOR_BY_ID_TO_COMPANY);
  const [assocCollabo] = useMutation(CREATE_EMPLOYMENT_COLLAB);
  const [initEvaluation] = useMutation(CREATE_AVAL_PERIOD);
  const [fireCollab] = useMutation(DELETE_EMPLOYMENT_BY_COLLABORATOR);
  const [delNotification] = useMutation(DELETE_NOTIFICATION_MUTATION);

  const loadCollabData = () => {
    get_companies({
      context: {
        headers: {
          authorization: `Bearer ${jwt}`,
        },
      },
      variables: {
        userId: employeerID.toString()
      },
    }).then((data) => {
      const lastId = data?.data?.companies?.data?.map(companyItem => {
        return companyItem.id;
      });
      setCurrentCompanyId(lastId.toString());
    });
  };

  console.log(currentCompanyId);

  const proceedAssoc = async ({ companyId, salary, startDate, endDate, positionCollaborator }) => {
    setLoadingCheck(true);
    try {
      // Primeiro, associar o colaborador
      const assocResult = await assocCollabo({
        context: {
          headers: {
            authorization: `Bearer ${jwt}`,
          },
        },
        variables: {
          companyId: [companyId.toString()],
          collaboratorId: [idCollaborador.toString()],
          position: collabRole ?? 'N/A',
          startDate: startDate,
          endDate: endDate,
          status: 'active',
          Salary: parseFloat(salary),
        },
      });

      console.log("RESULT ASSOC: ", assocResult);
      console.log(companyId);
      // Verificar se o assocCollabo teve sucesso
      if (assocResult?.data) {
        // Criar o período de avaliação
        const evalResult = await initEvaluation({
          context: {
            headers: {
              authorization: `Bearer ${jwt}`,
            },
          },
          variables: {
            companies: [companyId.toString()],
            collaborators: [idCollaborador.toString()],
            start: startDate,
            end: endDate,
            description: 'Novo período com contratação',
            isActive: true,
          },
        });

        const resultado = await associarColaborador();

        console.log("RESULT: ", resultado);
        console.log("RESULT EVAL: ", evalResult);

        if (resultado && evalResult?.data) {
          if (notificationId != null) {
            delNotification({
              context: {
                headers: {
                  authorization: `Bearer ${jwt}`,
                },
              },
              variables: {
                id: notificationId.toString()
              },
            }).then((endResult) => {
              if (endResult) {
                toast.success("Operação feita com Sucesso!");
              } else {
                toast.warning("Não foi possivel actualizar o estado da notificação!!!");
              }
            })
          } else {
            toast.success("Colaborador contratado e avaliação inicial criada com sucesso!");
          }
          setTimeout(() => {
            window.location.reload();
          }, 150);
        } else {
          console.warn("Associação concluída, mas a criação da avaliação falhou.");
          toast.warning("Colaborador contratado, mas houve um problema ao criar a avaliação ou associando a empresa ao colaborador.");
        }
      } else {
        throw new Error("Falha na associação do colaborador.");
      }

      // Fechar modal (se necessário)
      handleClose();
    } catch (error) {
      console.error("Erro ao processar a associação:", error);
      toast.error("Erro ao tentar contratar o colaborador.");
    } finally {
      setLoadingCheck(false);
    }
  };



  const [updateCollaborator] = useMutation(UPDATE_COMPANY_C);

  const associarColaborador = async () => {


    setLoadingCheck(true);
    try {
      const { data } = await get_collaborator({
        context: {
          headers: {
            authorization: `Bearer ${jwt}`,
          },
        },
        variables: {
          id: idCollaborador.toString(),
        },
      });

      const currentCompanies = data?.collaborator?.data?.attributes?.companies.data


      const newCompany = {
        id: currentOption || '',
      }

      const updatedCompanies = [
        ...new Set([...currentCompanies, newCompany].map(company => company.id)),
      ];


      await updateCollaborator({
        context: {
          headers: {
            authorization: `Bearer ${jwt}`,
          },
        },
        variables: {
          id: idCollaborador,
          data: {
            companies: updatedCompanies,
          },
        },
      });


      //toast.success(`Company associada com sucesso ao colaborador!`);
      handleClose();
      return true
      /*   setTimeout(() => {
          window.location.reload();
        }, 1500); */
    } catch (error) {

      console.error("Erro ao associar company ao colaborador:", error);
      toast.error("Erro ao associar company ao colaborador.");
      return false
    } finally {
      setLoadingCheck(false);
    }
  };


  const desassociarColaborador = async () => {
    setLoadingCheck(true); // Inicia o carregamento
    try {
      const { data } = await get_collaborator({
        context: {
          headers: {
            authorization: `Bearer ${jwt}`,
          },
        },
        variables: {
          id: idCollaborador,
        },
      });

      // Obtém a lista de companies existentes
      const currentCompanies = data?.data?.collaborator?.attributes?.companies?.data.map(comp => comp.id) || [];

      // Remove a company da lista
      const updatedCompanies = currentCompanies.filter(companyId => companyId !== currentCompanyId.toString());

      // Chama a mutação para atualizar o colaborador
      await updateCollaborator({
        context: {
          headers: {
            authorization: `Bearer ${jwt}`,
          },
        },
        variables: {
          id: idCollaborador.toString(),
          data: {
            companies: updatedCompanies,
          },
        },
      });



      toast.success(`Company desassociada com sucesso do colaborador!`);
      handleClose();
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    } catch (error) {
      console.error("Erro ao desassociar company do colaborador:", error);
      toast.error("Erro ao desassociar company do colaborador.");
    } finally {
      setLoadingCheck(false); // Finaliza o carregamento
    }
  };


  const [companies, setCompanies] = useState([]);
  const [companiesItems, setCompaniesItems] = useState([]);

  const [getCompanys_, { loading, data }] = useLazyQuery(GET_COMPANIES, {
    context: {
      headers: {
        authorization: `Bearer ${jwt}`,
      },
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (data) {
      const { companies } = data;
      setCompanies(companies.data);

      companies.data.map((items) => {
        console.log("AAAAAAADDDDDDDDEUS", items.id);
      })
      // Processar os dados diretamente aqui
      const items = companies.data.map((item) => ({
        value: item?.id,
        label: item?.attributes?.name,
      }));
      setCompaniesItems(items);
    }
  }, [data]);

  const fireCollaborator = () => {
    if (idCollaborador) {
      handleUpdateEmployment(idCollaborador);
      // return;
      /*alert(idCollaborador)
      try {
        fireCollab({
          context: {
            headers: {
              authorization: `Bearer ${jwt}`,
            },
          },
          variables: {
            id: idCollaborador.toString()
          },
        }).then((response) => {
          if (response) {
            console.log("RRRRRRRR", response);
            toast.success("Feito com successo");
            return;
            setTimeout(() => {
              window.location.reload();
            }, 150);
          } else {
            toast.error("Algo deu errado, tente mais tarde");
          }
        });
      } catch (error) {
        toast.error("Algo deu errado, tente mais tarde");
      }*/
    }
  };

  const handleUpdateEmployment = async (collaboratorId) => {
    try {
      const response = await updateEmploymentToNull({
        context: {
          headers: {
            authorization: `Bearer ${jwt}`,
          },
        },
        variables: { collaboratorId },
      });
      if (notificationId != null) {
        delNotification({
          context: {
            headers: {
              authorization: `Bearer ${jwt}`,
            },
          },
          variables: {
            id: notificationId.toString()
          },
        }).then((endResult) => {
          if (endResult) {
            toast.success("Feito com successo");
            //toast.success("Operação feita com Sucesso!");
          } else {
            toast.warning("Não foi possivel actualizar o estado da notificação!!!");
          }
        })
      } else {
        toast.success("Feito com sucesso!");
      }
      // console.log("Mutation Response:", response);
      setTimeout(() => {
        window.location.reload();
      }, 250);
    } catch (err) {
      console.error("Error updating employment:", err);
      toast.error("Algo deu errado, tente mais tarde");
    }
  };

  useEffect(() => {
    getCompanys_();
    loadCollabData();
  }, []);

  useEffect(() => {
    if (idCompany) {
      setCurrentOption(idCompany);
    }
  }, [idCompany]);


  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="associate-dialog-title"
      maxWidth="md"
    >
      <DialogTitle id="associate-dialog-title" style={{ textAlign: 'center' }}>
        {loadingCheck ? 'Verificando associação...' : (
          action == 0
            ? `Deseja desassociar este colaborador da sua empresa?`
            : `Deseja mesmo associar este colaborador ${nameCollaborador != null ? nameCollaborador : ''} à sua empresa?`
        )}
      </DialogTitle>
      <DialogContent style={{ textAlign: 'center' }}>
        {loadingCheck ? (
          <CircularProgress />
        ) : isAlreadyAssociated ? (
          <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={fireCollaborator}
                style={{ marginRight: '10px' }}
              // disabled={loadingAssoc}
              >
                Descontratar
                {/* {loadingAssoc ? 'Associando...' : 'Sim, Associar'}  */}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleClose}
              >
                Cancelar
              </Button>
            </Grid>
          </Grid>
        ) : (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12}></Grid>
              <Grid item lg={6} xs={12}>
                <Box>
                  {
                    idCompany != null ? (

                      <Autocomplete
                        options={companiesItems}
                        getOptionLabel={(option) => option.label}
                        value={companiesItems.find((item) => item.value === idCompany) || null}
                        onChange={(event, newValue) => {
                          if (!idCompany) {
                            setCurrentOption(newValue?.value);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Selecione uma empresa"
                            variant="outlined"
                            fullWidth
                            sx={{
                              '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'primary.main',
                              },
                              '&.Mui-focused .MuiInputLabel-root': {
                                color: 'primary.main',
                              },
                            }}
                            InputProps={{
                              ...params.InputProps,
                              readOnly: !!idCompany,
                            }}
                          />
                        )}
                        disabled={!!idCompany}
                      />
                    ) : (
                      <Autocomplete
                        options={companiesItems}
                        getOptionLabel={(option) => option.label}
                        onChange={(event, newValue) => {
                          setCurrentOption(newValue?.value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Selecione uma empresa"
                            variant="outlined"
                            fullWidth
                            sx={{
                              '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'primary.main',
                              },
                              '&.Mui-focused .MuiInputLabel-root': {
                                color: 'primary.main',
                              },
                            }}
                          />
                        )}
                      />
                    )
                  }
                </Box>
              </Grid>

              {/* Remuneração */}
              <Grid item lg={6} xs={12}>
                <TextField
                  label="Remuneração"
                  type="number"
                  fullWidth
                  onChange={(e) => setSalary(e.target.value)}
                  sx={{
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'primary.main',
                    },
                    '&.Mui-focused .MuiInputLabel-root': {
                      color: 'primary.main',
                    },
                  }}
                />
              </Grid>

              {/* Outro Campo de Texto */}
              <Grid item lg={6} xs={12}>
                <TextField
                  label="Data Inicio"
                  type="date"
                  fullWidth
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'primary.main',
                    },
                    '&.Mui-focused .MuiInputLabel-root': {
                      color: 'primary.main',
                    },
                  }}
                />
              </Grid>

              {/* Campo de Data */}
              <Grid item lg={6} xs={12}>
                <TextField
                  label="Data Fim"
                  type="date"
                  fullWidth
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'primary.main',
                    },
                    '&.Mui-focused .MuiInputLabel-root': {
                      color: 'primary.main',
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12}></Grid>

              <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      if (action === 1) {
                        if (currentOption && salary && startDate && endDate) {
                          setTimeout(() => {
                            proceedAssoc({
                              companyId: currentOption,
                              salary,
                              startDate,
                              endDate,
                            });
                          }, 100);
                        } else {
                          alert('Preencha todos os campos obrigatórios!');
                        }
                        // associarColaborador();
                      } else {
                        // desassociarColaborador();
                        // alert(action)
                        // alert(currentOption && salary && startDate && endDate)
                      }
                    }}
                    style={{ marginRight: '10px' }}
                  // disabled={loadingAssoc}
                  >
                    Associar
                    {/* {loadingAssoc ? 'Associando...' : 'Sim, Associar'}  */}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleClose}
                  >
                    Cancelar
                  </Button>
                </Grid>
              </Grid>
            </Grid>


            {/* <Button
              variant="contained"
              color="primary"
              onClick={() => {
                if (action == 1) {
                 associarColaborador(); 
                } else {
                 desassociarColaborador();
                } 
              }}
              style={{ marginRight: '10px' }}
            // disabled={loadingAssoc}
            >
              Sim, Associar
               {loadingAssoc ? 'Associando...' : 'Sim, Associar'} 
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleClose}
            // disabled={loadingAssoc}
            >
              Cancelar
            </Button> */}
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default CollaboratorToCompany;
