import { gql } from "@apollo/client";

export const GET_COLLABORATOR_BY_ID = gql`
  query getCollaboratorbyid($id: ID!) {
    collaborator(id: $id) {
      data {
        id
        attributes {
          companies { 
          data { id attributes { name email   users_permissions_user { 
          data { id attributes { username } }
        }} }
        }
          users_permissions_user {
            data {
              id
              attributes {
                username
                confirmed
              }
            }
          }
          name
          email
          phone
          web
          intro
          address
          
              
                evaluations {
                  data {
                    id
                    attributes {
                      star
                      Comment
                      companies {
                        data {
                          id
                          attributes {
                            name
                          }
                        }
                      }
                      evaluation_period {
                        data {
                          id
                          attributes {
                            start
                            end
                            description
                          }
                        }
                      }
                    }
                  }
                }
              
            
          
          postRole {
            data {
              id
              attributes {
                postRole
              }
            }
          }
          city {
            data {
              id
              attributes {
                name
              }
            }
          }
          country {
            data {
              id
              attributes {
                name
              }
            }
          }
          image {
            data {
              id
              attributes {
                url
              }
            }
          }
          experiences {
            data {
              id
              attributes {
                description
                startDate
                endDate
                postRole {
                  data {
                    id
                    attributes {
                      postRole
                    }
                  }
                }
                expEntity {
                  data {
                    id
                    attributes {
                      name
                    }
                  }
                }
                country {
                  data {
                    id
                    attributes {
                      name
                    }
                  }
                }
              }
            }
          }
          educations {
            data {
              id
              attributes {
                description
                startDate
                endDate
                course {
                  data {
                    id
                    attributes {
                      name
                    }
                  }
                }
                faculty {
                  data {
                    id
                    attributes {
                      name
                    }
                  }
                }
                country {
                  data {
                    id
                    attributes {
                      name
                    }
                  }
                }
              }
            }
          }
          expertise {
            data {
              id
              attributes {
                description
                expertise {
                  data {
                    id
                    attributes {
                      name
                    }
                  }
                }
              }
            }
          }
          skills {
            data {
              id
              attributes {
                percentual
                itTool {
                  data {
                    id
                    attributes {
                      name
                    }
                  }
                }
              }
            }
          }
          profiles {
            data {
              id
              attributes {
                urlProfile
                description
                platform {
                  data {
                    id
                    attributes {
                      name
                      icon {
                        data {
                          id
                          attributes {
                            url
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          awards {
            data {
              id
              attributes {
                name
                description
              }
            }
          }
          portfolios {
            data {
              id
              attributes {
                name
                description
                image {
                  data {
                    id
                    attributes {
                      url
                    }
                  }
                }
              }
            }
          }
          client {
            data {
              id
              attributes {
                companies {
                  data {
                    id
                    attributes {
                      name
                      image {
                        data {
                          id
                          attributes {
                            url
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;


export const GET_COLLABORATOR_BY_ID_TO_COMPANY = gql`
query getCollaboratorByCompany($id:ID!) {
  collaborator(id:$id) {
    data {
      id
      attributes {
        name
        email
        phone
        web
        intro
        address
        postRole{data{id attributes{postRole}}}
        city{data{id attributes{name}}}
        country{data{id attributes{name}}}
        image{data{id attributes{url}}}
        experiences{data{id attributes{ description startDate endDate postRole{data{id attributes{postRole}}} expEntity{data{id attributes{name}}}country{data{id attributes{name}}}}}}
        educations{data{id attributes{ description startDate endDate course{data{id attributes{name}}}faculty{data{id attributes{name}}}country{data{id attributes{name}}}}}}
        expertise{data{id attributes{description expertise{data{id attributes{name}}}}}}  
        skills{data{id attributes{percentual itTool{data{id attributes{name}}}}}}
        profiles{data{id attributes{urlProfile description platform{data{id attributes{name icon{data{id attributes{url}}}}}}}}} 
        awards{data{id attributes{name description}}} 
        portfolios{data{id attributes{name description image{data{id attributes{url}}}}}}
        client{data{id attributes{companies{data{id attributes{name image{data{id attributes{url}}}}}}}}}
          companies{data{id attributes{name}}}
     }
    }
  }
}


`;
export const GET_COLLABORATORS_BY_COMPANY_ID = gql`
query getCollaboratorsByCompanyId($companyId: ID!) {
  collaborators(filters: { companies: { id: { eq: $companyId } } }) {
    data {
      id
      attributes {
        name
        email
        phone
        web
        intro
        address
        evaluations { 
          data {
            id
            attributes {
              star
              Comment
              companies { 
                data {
                  id
                  attributes {
                    name
                  }
                }
              }
              evaluation_period { 
                data {
                  id
                  attributes {
                    start
                    end
                    description
                  }
                }
              }
            }
          }
  }
        }
        
       
        
    }
  }
}
`;


export const GET_COLLABORATORS_EVALUATIONS_BY_COMPANY_ID = gql`
query GetEvaluationsByCompany($companyId: ID!) {
  evaluations(filters: { collaborator: { companies: { id: { eq: $companyId } } } }) {
    data {
      id
      attributes {
        star
        Comment
        collaborator {
          data {
            id
            attributes {
              name
            }
          }
        }
        companies {
          data {
            id
            attributes {
              name
            }
          }
        }
        evaluation_period {
          data {
            id
            attributes {
              description
              start
              end
            }
          }
        }
        evaluation_methods {
          data {
            id
            attributes {
              name
            }
          }
        }
        scores {
          data {
            id
            attributes {
              Score
              criterion {
                data {
                  id
                  attributes {
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

`;

export const GET_COLLABORATOR_EVALUATIONS_BY_COLLABORATTOR_ID = gql`
query GetEvaluationsByCollaborator($collaboratorId: ID!) {
  evaluations(filters: { collaborator: { id: { eq: $collaboratorId } } }) {
    data {
      id
      attributes {
        star
        Comment
        collaborator {
          data {
            id
            attributes {
              name
            }
          }
        }
        companies {
          data {
            id
            attributes {
              name
            }
          }
        }
        evaluation_period {
          data {
            id
            attributes {
              description
              start
              end
            }
          }
        }
        evaluation_methods {
          data {
            id
            attributes {
              name
              criteria {
            data {
              id
              attributes {
                name
                percentage
              }
            }
          }
            }
          }
        }
        scores {
          data {
            id
            attributes {
              Score
              criterion {
                data {
                  id
                  attributes {
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`;
