import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';

const LineMixedChart = () => {
  const [state] = useState({
    series: [
      {
        name: 'Colab',
        type: 'column',
        data: [440, 505, 414, 671, 227, 413, 201, 352, 752, 320, 257, 160],
      },
      {
        name: 'Aval',
        type: 'line',
        data: [23, 42, 35, 27, 43, 22, 17, 31, 22, 22, 12, 16],
      },
    ],
    options: {
      chart: {
        height: 350,
        type: 'line',
        toolbar: {
          show: false, // Oculta a toolbar
        },
      },
      stroke: {
        width: [0, 4],
      },
      title: {
        text: '',
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: [1],
      },
      labels: [
        '01 Jan 2001',
        '02 Jan 2001',
        '03 Jan 2001',
        '04 Jan 2001',
        '05 Jan 2001',
        '06 Jan 2001',
        '07 Jan 2001',
        '08 Jan 2001',
        '09 Jan 2001',
        '10 Jan 2001',
        '11 Jan 2001',
        '12 Jan 2001',
      ],
      yaxis: [
        {
          title: {
            text: '',
          },
        },
        {
          opposite: true,
          title: {
            text: '',
          },
        },
      ],
    },
  });

  return (
    <div className='card'>
      <div id="chart">
        <ReactApexChart options={state.options} series={state.series} type="line" height={550} />
      </div>
    </div>
  );
};

export default LineMixedChart;
