import {
  Avatar,
  Button,
  Grid,
  InputLabel,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { useContext, useEffect } from "react";
import { LoginContext } from "../../contexts/login-context";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useLazyQuery } from "@apollo/client";
import { GET_EMPLOYEER_BY_EMAIL } from "./query.gql";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { UserRoleContext } from "../../contexts/userRole-context";
import { LanguageContext } from "../../translate/languages/LanguageContext";
import i18n from "../../translate/i18n";

const validationEmailForm = yup
  .object({
    email: yup
      .string()
      .email("Insira um email válido")
      .required("O email é obrigatório"),
  })
  .required();

const LoginEmail = () => {
  let navigate = useNavigate();

  const { selectedLanguage } = useContext(LanguageContext);
  const { loginData, setLoginData } = useContext(LoginContext);
  const { uRoleContext, setURoleContext } = useContext(UserRoleContext);
  const [getEmployeerByEmail] = useLazyQuery(GET_EMPLOYEER_BY_EMAIL);
  /**
  useEffect(() => {
    if (loginData.isResetPassword === true) {
      navigate("password/create");
    }
  }, [loginData]);
  */

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationEmailForm),
  });

  const toRegister = () => navigate("/register");
  const loginEmailForm = (formData) => {
    getEmployeerByEmail({
      variables: {
        filters: {
          email: {
            eq: formData.email,
          },
          blocked: {
            eq: false,
          },
        },
      },
      fetchPolicy: "network-only",
    }).then((data) => {
      console.log(data?.data)

      console.log("EMPLOYERR DATA::: ", data);
      if (data?.data?.usersPermissionsUsers?.data?.length === 0) {
        toast.error("Não foi possivel encontrar a sua conta!!");
      } else {
        console.log("LOGIN DATA: ", data);
        localStorage.setItem("userId", data?.data?.usersPermissionsUsers?.data[0]?.id);
        let employeerData = data.data.usersPermissionsUsers.data[0];
        let userRole =
          employeerData?.attributes?.userRole?.data?.attributes?.role ||
          "company";
        setURoleContext(userRole);
        setLoginData((ld) => {
          return {
            ...ld,
            email: employeerData.attributes.email,
          };
        });

        if (employeerData.attributes.isResetPassword === true) {
          setLoginData((ld) => {
            return {
              ...ld,
              email: employeerData.attributes.email,
              id: employeerData.id,
              isResetPassword: true,
            };
          });
        }
        navigate("password");
      }
    });
  };

  return (
    <form onSubmit={handleSubmit(loginEmailForm)} noValidate>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h3">
            {i18n.t("login.login.access", { lng: selectedLanguage })}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <InputLabel>
            {i18n.t("login.register.email", { lng: selectedLanguage })}
          </InputLabel>
          <TextField
            required
            defaultValue=""
            fullWidth
            name="email"
            id="email"
            {...register("email")}
            helperText={errors.email?.message}
          />
        </Grid>
        <Grid item xs={12}>
          <Button type="submit" size="medium" variant="contained" fullWidth>
            {i18n.t("button.next", { lng: selectedLanguage })}
          </Button>
          <ToastContainer />
        </Grid>
        <Grid item xs={12}>
          <div style={{ display: "flex" }}>
            <Typography
              sx={{ p: 2 }}
              variant="body2"
              children={i18n.t("login.login.signMessage", {
                lng: selectedLanguage,
              })}
            />
            <Button
              type="button"
              size="small"
              variant="text"
              onClick={toRegister}
            >
              {i18n.t("login.login.signUp", { lng: selectedLanguage })}
            </Button>
          </div>
        </Grid>
      </Grid>
    </form>
  );
};

export default LoginEmail;
