import i18n from "../../../translate/i18n";

const AvalWelcomeCard = ({ title, subtitle, action = null }) => {
  return (
    <>
      <div className="bg-white rounded-[10px] amazing-shadow p-4 w-full">
        <div className="flex flex-col w-full">
          <small
            className="block w-full text-el-1 text-gray-400"
            style={{
              fontSize: 15,
            }}
          >{title}</small>
          <small className="text-[#F89220] font-bold w-full" style={{ fontSize: '1.25rem' }}>{subtitle}</small>
          <div className="flex items-center w-full mt-4 gap-1.5">
            <a
              href={action != null ? action : '/profile'}
              className="bg-gray-100 text-[#F89220] py-[0.5rem] px-[0.75rem] rounded-[0.25rem] text-sm"
            >
              {i18n.t('dashboard.findDev')}
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default AvalWelcomeCard;