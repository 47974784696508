import React, { useContext } from 'react';
import { Radar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from 'chart.js';
import { LanguageContext } from '../../../translate/languages/LanguageContext';
import i18n from '../../../translate/i18n';

// Registrar os componentes necessários do Chart.js
ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend);

const RadarChartWithColors = () => {
  const { selectedLanguage } = useContext(LanguageContext);

  const data = {
    labels: [
      i18n.t('chart.administration', { lng: selectedLanguage }),
      i18n.t('chart.rh', { lng: selectedLanguage }),
      i18n.t('chart.it', { lng: selectedLanguage }),
      i18n.t('chart.systemTec', { lng: selectedLanguage }),
      i18n.t('chart.information', { lng: selectedLanguage }),
      i18n.t('chart.others', { lng: selectedLanguage }),
    ],
    datasets: [
      {
        label: 'Sectores', // Esconde a label "Setores"
        data: [10, 60, 90, 70, 85, 75],
        backgroundColor: 'rgba(255, 159, 64, 0.2)', // Cor de preenchimento
        borderColor: 'rgba(255, 159, 64, 1)', // Cor da linha
        borderWidth: 2,
        pointBackgroundColor: 'rgba(255, 159, 64, 1)', // Cor dos pontos
        pointBorderColor: '#fff', // Cor da borda dos pontos
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(255, 159, 64, 1)',
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false, // Permite que o gráfico ocupe o tamanho do contêiner pai
    plugins: {
      legend: {
        display: false, // Esconde a legenda inteira (se necessário)
      },
      tooltip: {
        titleFont: {
          weight: 'bold', // Deixa o título do tooltip em negrito
        },
      },
    },
    scales: {
      r: {
        ticks: {
          beginAtZero: true,
          stepSize: 20, // Intervalos no eixo radial
          font: {
            weight: 'bold', // Deixa as labels das escalas em negrito
          },
        },
        pointLabels: {
          font: {
            weight: 'bold', // Deixa as labels das categorias em negrito
          },
        },
      },
    },
  };

  return (
    <div
      style={{ width: '100%', height: '400px' }} // O contêiner pai define altura fixa, e a largura é 100%
      className="bg-white amazing-shadow rounded-[10px] p-4 text-center"
    >
      <Radar data={data} options={options} />
    </div>
  );
};

export default RadarChartWithColors;
