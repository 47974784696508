import React, { useState } from "react";
import { Email, Home, Phone, Place } from "@mui/icons-material";
import SectionHeader from "./SectionHeader";
import i18n from "../../../../translate/i18n";
import { QRCodeCanvas } from 'qrcode.react';

const HeaderAlt = ({ owner, lng }) => {

  const { REACT_APP_API_URL } = process.env;
  const [isImageLoaded, setIsImageLoaded] = useState(true);

  const [text, setText] = useState('');

  const handleInputChange = (e) => {
    setText(e.target.value);
  };

  const handleImageError = () => {
    setIsImageLoaded(false);
  };

  const handleImageLoad = () => {
    setIsImageLoaded(true);
  };

  const enlargePaddingTop = () => {
    const data = REACT_APP_API_URL + owner?.avatar ?? 'avatar.jpg';
    if (!data || !isImageLoaded) return "hidden";
    if (data.length > 0) return "flex";
    return "hidden";
  };

  return (
    <>
      <div className="layoutDoc-header-contain flex items-start gap-2 text-white relative">
        <div className="flex items-start">
          <div className={`img-layout w-[380px] h-[240px] relative items-center justify-center ${enlargePaddingTop()}`}>
            <img
              src={`${REACT_APP_API_URL}${owner?.avatar ?? 'avatar.jpg'}`}
              className={`layout-header-img ${enlargePaddingTop()} w-[60%] aspect-square max-w-[80%]`}
              onError={handleImageError}
              onLoad={handleImageLoad}
            />
          </div>
          <div className="flex flex-col self-start items-start w-full ml-2">
            <span className="font-black uppercase default-color w-full">{i18n.t('cv.info', { lng: lng })}</span>
            <h4 className="font-bold curv-owner w-full mt-2">{owner.name}</h4>
            <div className="flex items-center w-full">
              <span className="default-color mr-2 augmented-top"> <Place sx={{ fontSize: 16 }} /> </span>
              <span className="curv-tiny-text">{owner.city}, {owner.address}</span>
            </div>
            <div className="flex items-center w-full">
              <span className="default-color mr-2 augmented-top"> <Phone sx={{ fontSize: 16 }} /> </span>
              <span className="curv-tiny-text">+244 {owner.phone}</span>
            </div>

            <div className="flex items-center w-full">
              <span className="default-color mr-2 augmented-top"> <Email sx={{ fontSize: 18 }} /> </span>
              <span className="curv-tiny-text">{owner.email}</span>
            </div>
            {/* <div className="flex flex-col w-full items-center gap-2 mt-2">
              <div className="flex items-center w-full">
                <div className="flex items-center">
                  <span className="curv-tiny-text default-color mr-2">{i18n.t('cv.sex', { lng: lng })}</span>
                  <span className="curv-tiny-text">{owner.gender === 'M' ? 'Masculino' : 'Feminino'}</span>
                </div>
                <span className="mx-2">|</span>
                <div className="flex items-center">
                  <span className="curv-tiny-text default-color mr-2">{i18n.t('cv.nationality', { lng: lng })}</span>
                  <span className="curv-tiny-text whitespace-nowrap">{owner.country ?? 'N/A'}</span>
                </div>
              </div>
              <div className="flex items-center w-full -mt-2">
                <span className="default-color mr-2 curv-tiny-text">{i18n.t('cv.datanasc', { lng: lng })}</span>
                <span className="curv-tiny-text">{owner.birthDate ?? '24.08.1985'}</span>
              </div>
            </div> */}
            <div className="mt-14"></div>
          </div>
        </div>

        <div className="absolute top-4 right-8 w-[100px] flex flex-col items-center justify-center mr-2">
          <span className="default-color -mt-2 mb-2">
            <img src="/logo2.svg" alt="" srcset="" />
          </span>

          {owner.email && (
            <QRCodeCanvas
              value={owner.email}
              size={140}
              bgColor="#ffffff"
              fgColor="#000000"
              level="H" // Nível de correção de erro (L, M, Q, H)
              includeMargin={true} // Inclui margem ao redor do código
            />
          )}
        </div>

      </div>
    </>
  );
};

const Header = () => {
  return (
    <>Items</>
  );
};

export default HeaderAlt;