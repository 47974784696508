import { Link } from "react-router-dom";

const AnalysisCardItem = ({ title, link, count, state }) => {
  const to = typeof link === "string" ? { pathname: link, state: { evaluations: state || [] } } : link;



  return (
    <div className="flex flex-col items-center justify-center bg-white shadow-md border px-1 py-6 rounded-md flex-1">
      <span className="font-bold" style={{ textAlign: 'center', justifySelf: 'center' }}>{title}</span>
      <span className="font-bold text-[#FF9213_!important]" style={{ fontSize: '2rem' }}>{count}</span>
      <Link
        to={to}
        className="bg-[#F7F8F8] text-[#ff8213] py-2 px-4 rounded-[0.25rem] shadow-sm border border-[rgba(0,0,0,0.025)] text-[0.85rem]"
      >
        Ver todos
      </Link>
    </div>
  );
};

export default AnalysisCardItem;
