import { createTheme } from "@mui/material";

const theme = createTheme({
  components: {
    // Name of the component
    MuiTextField: {
      defaultProps: {
        size: "medium",
      },
    },

    MuiBackdrop: {
      defaultProps: {
        sx: {
          // filter: "blur(5px)",
        },
      },
    },
  },
  palette: {
    primary: {
      main: "#F7931E",
      contrastText: "#fff",
    },
    secondary: {
      main: "#2F272D",
      contrastText: "#FFFFFF",

    },
  },




  typography: {
    fontFamily: "Montserrat",
    fontWeightLight: 400,
    fontWeightRegular: 500,
    fontWeightMedium: 600,
    fontWeightBold: 800,
    h1: {
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontWeight: 300,
      lineHeight: "22px",
      letterSpacing: "0em",
      textAlign: "left",
      textTransform: "uppercase",
    },
    h2: {
      fontFamily: "Montserrat",
      fontSize: "24px",
      fontWeight: 600,
      lineHeight: "30px",
      letterSpacing: "0em",
      textAlign: "left",
    },
    h3: {
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontWeight: 600,
      lineHeight: "24.38px",
      letterSpacing: "0em",
      textAlign: "left",
      color: "#2F272D",
    },
  },
});

export default theme;
