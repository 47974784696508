import { gql } from "@apollo/client";

export const CREATE_EVALUATION_METHOD = gql`
mutation CreateEvaluationMethod($data: EvaluationMethodInput!) {
  createEvaluationMethod(data: $data) {
    data {
      id
      attributes {
        name
        criteria {
          data {
            id
            attributes {
              name
              percentage
            }
          }
        }
  
}
}
}
}
`;

export const CREATE_EVALUATION_CRITERY = gql`
mutation CreateCriteria($data: CriteriaInput!) {
  createCriteria(data: $data) {
    data {
      id
      attributes {
        name
        percentage
   }
}
}
}
`;

export const GET_EVALUATION_CRITERY = gql`
query GetEvaluationMethods($pagination: PaginationArg, $filters: EvaluationMethodFiltersInput) {
  evaluationMethods(pagination: $pagination, filters: $filters) {
    data {
      id
      attributes {
        name
        company {
          data {
            id
            attributes {
              name
            }
          }
        }
        criteria {
          data {
            id
            attributes {
              name
              percentage
            }
          }
        }
  }
}
}
}`;

export const GET_EVALUATION_METHODS_BY_COMPANY_ID = gql`
  query GetEvaluationMethodsByCompanyId($companyId: ID!, $pagination: PaginationArg) {
    evaluationMethods(
      filters: { company: { id: { eq: $companyId } } }
      pagination: $pagination
    ) {
      data {
        id
        attributes {
          name
          company {
            data {
              id
              attributes {
                name
              }
            }
          }
          criteria {
            data {
              id
              attributes {
                name
                percentage
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_EVALUATION_METHOD_BY_ID = gql`
  query GetEvaluationMethodById($methodId: ID!) {
    evaluationMethod(id: $methodId) {
      data {
        id
        attributes {
          name
          company {
            data {
              id
              attributes {
                name
              }
            }
          }
          criteria {
            data {
              id
              attributes {
                name
                percentage
              }
            }
          }
        }
      }
    }
  }
`;
